import { debounce } from "throttle-debounce";
import {
  APISite,
  GetSitesQueryParams,
  UploadFaviconResponse,
} from "../../server/types/index.js";
import { getActiveSite } from "../selectors/sites.js";
import { PatchSiteClient, ThunkAction, ThunkDispatch } from "../types/index.js";
import { calculatePagination, fetch } from "../utils/utils.js";
import { showAlert } from "./Alerts.js";

export type Action =
  | { type: "GET_SITES_SUCCESS"; sites: APISite[]; keepExisting: boolean }
  | { type: "GET_SITE_SUCCESS"; site: APISite }
  | { type: "GET_SITE_ERROR"; error: Error }
  | { type: "GET_SITE_START" }
  | { type: "PATCH_SITE_START"; siteRequest: PatchSiteClient };

export const getSites = (
  params: GetSitesQueryParams,
  keepExisting: boolean,
): ThunkAction<Promise<void>> => {
  return (dispatch, getState) => {
    const page = calculatePagination(
      keepExisting,
      getState().sites.allItems.length,
    );

    return fetch({ dispatch })
      .get("sites", {
        params: {
          ...params,
          page,
        },
      })
      .then((res) => {
        dispatch(getSitesSuccess(res.data, keepExisting));
      })
      .catch((error) => {
        dispatch(showAlert("Die Webseiten Liste konnte nicht geladen werden!"));
        throw error;
      });
  };
};

const getSitesSuccess = (sites: APISite[], keepExisting: boolean): Action => ({
  type: "GET_SITES_SUCCESS",
  sites,
  keepExisting,
});

export const getSite = (siteId: string): ThunkAction<Promise<void>> => {
  return (dispatch, getState) => {
    if (getState().loadStates.site !== "unloaded") {
      return Promise.resolve();
    }

    dispatch(getSiteStart());

    return fetch({ dispatch })
      .get(`sites/${siteId}`)
      .then((res) => {
        dispatch(getSiteSuccess(res.data));
      })
      .catch((error) => {
        dispatch(getSiteError(error));
        throw error;
      });
  };
};

const getSiteStart = (): Action => ({
  type: "GET_SITE_START",
});

export const getSiteSuccess = (site: APISite): Action => ({
  type: "GET_SITE_SUCCESS",
  site,
});

const getSiteError = (error: Error): Action => ({
  type: "GET_SITE_ERROR",
  error,
});

/**
 * Action for patching the site in the store and through the API
 * @param directSuccess Disabling this doesn’t show a notification
 * and debounces the API request
 */
export const patchSite =
  (siteId: string, siteRequest: PatchSiteClient): ThunkAction<void> =>
  (dispatch, getState) => {
    const previousSite = getState().sites.byId[siteId];
    dispatch(patchSiteStart(siteRequest));

    debouncedPatchSiteRequest({
      dispatch,
      previousSite,
      siteId,
      siteRequest,
    });
  };

interface PatchSiteRequestParams {
  dispatch: ThunkDispatch;
  siteId: string;
  siteRequest: PatchSiteClient;
  previousSite: APISite;
}

const patchSiteRequest = ({
  dispatch,
  siteId,
  previousSite,
  siteRequest,
}: PatchSiteRequestParams) =>
  fetch({ dispatch })
    .patch(`sites/${siteId}`, siteRequest)
    .catch((error) => {
      dispatch(
        showAlert(
          "Beim Aktualisieren der Seiteneinstellungen ist ein Fehler aufgetreten!",
        ),
      );
      // Reset to previous settings is something went wrong
      dispatch(patchSiteStart(previousSite));
      throw error;
    });

const debouncedPatchSiteRequest = debounce(2000, patchSiteRequest);

const patchSiteStart = (siteRequest: PatchSiteClient): Action => ({
  type: "PATCH_SITE_START",
  siteRequest,
});

export const uploadFavicon =
  (siteId: string, file: File): ThunkAction<Promise<void>> =>
  async (dispatch, getState) => {
    const formData = new FormData();
    formData.append("file", file);
    const { favicon } = getActiveSite(getState().sites);

    try {
      const resp = await fetch({ dispatch }).post<UploadFaviconResponse>(
        `sites/${siteId}/favicon`,
        formData,
      );
      dispatch({
        type: "PATCH_SITE_START",
        siteRequest: {
          favicon: {
            ...favicon,
            variant: 0,
            uploadURL: resp.data.url,
          },
        },
      });
    } catch (error) {
      dispatch(
        showAlert("Beim Hochladen des Favicons ist ein Fehler aufgetreten"),
      );
      throw error;
    }
  };

import { AxiosResponse } from "axios";
import {
  Picture,
  PictureGroup,
  ThunkAction,
  ThunkDispatch,
} from "../types/index.js";
import { fetch } from "../utils/utils.js";
import { showAlert } from "./Alerts.js";

export type Action =
  | { type: "GET_ALL_MEDIA_SUCCESS"; pictures: Picture[]; groupId?: number }
  | { type: "GET_ALL_MEDIA_ERROR"; error: Error }
  | { type: "GET_ALL_MEDIA_START" }
  | { type: "GET_ALL_MEDIA_GROUPS_SUCCESS"; groups: PictureGroup[] }
  | { type: "GET_ALL_MEDIA_GROUPS_ERROR"; error: Error }
  | { type: "GET_ALL_MEDIA_GROUPS_START" }
  | { type: "GET_MEDIA_GROUP_SUCCESS"; pictures: Picture[]; groupId: number }
  | { type: "GET_MEDIA_GROUP_ERROR"; error: Error }
  | { type: "GET_MEDIA_GROUP_START"; groupId: number }
  | { type: "UPLOAD_MEDIA_START" }
  | { type: "UPLOAD_MEDIA_ERROR"; error: Error }
  | { type: "UPLOAD_MEDIA_SUCCESS"; picture: Picture }
  | { type: "DELETE_MEDIA_START" }
  | { type: "DELETE_MEDIA_SUCCESS"; id: string }
  | { type: "DELETE_MEDIA_ERROR"; error: Error };

const fetchMedia = async ({
  siteId,
  dispatch,
  groupId,
}: {
  siteId: string;
  dispatch: ThunkDispatch;
  groupId?: number;
}): Promise<Picture[]> => {
  const { data } = await (<Promise<AxiosResponse<Picture[]>>>(
    fetch({ dispatch }).get(`sites/${siteId}/media`, { params: { groupId } })
  ));

  return data;
};

export const getAllMedia =
  (siteId: string): ThunkAction<Promise<void>> =>
  async (dispatch, getState) => {
    if (getState().loadStates.mediaLibrary.pictures !== "unloaded") {
      return;
    }

    dispatch(getAllMediaStart());
    try {
      const data = await fetchMedia({ siteId, dispatch });
      dispatch(getAllMediaSuccess(data));
    } catch (error) {
      error instanceof Error && dispatch(getAllMediaError(error));
      throw error;
    }
  };

export const getMediaGroup =
  (siteId: string, groupId: number): ThunkAction<Promise<void>> =>
  async (dispatch, getState) => {
    const loadStatus =
      getState().loadStates.mediaLibrary.picturesByGroupId[groupId];

    if (loadStatus !== "unloaded" && loadStatus !== undefined) {
      return;
    }

    dispatch(getMediaGroupStart(groupId));
    try {
      const data = await fetchMedia({ siteId, dispatch, groupId });
      dispatch(getMediaGroupSuccess(data, groupId));
    } catch (error) {
      error instanceof Error && dispatch(getMediaGroupError(error));
      throw error;
    }
  };

export const getAllMediaGroups =
  (siteId: string): ThunkAction<Promise<void>> =>
  async (dispatch, getState) => {
    if (getState().loadStates.mediaLibrary.groups !== "unloaded") {
      return;
    }

    dispatch(getMediaGroupsStart());
    try {
      const { data } = await (<Promise<AxiosResponse<PictureGroup[]>>>(
        fetch({ dispatch }).get(`sites/${siteId}/media/groups`)
      ));

      dispatch(getMediaGroupsSuccess(data));
    } catch (error) {
      error instanceof Error && dispatch(getMediaGroupsError(error));
      dispatch(
        showAlert("Beim Laden der Bildergruppen ist ein Fehler aufgetreten!"),
      );
      throw error;
    }
  };

export const getAllMediaSuccess = (pictures: Picture[]): Action => ({
  type: "GET_ALL_MEDIA_SUCCESS",
  pictures,
});

const getAllMediaError = (error: Error): Action => ({
  type: "GET_ALL_MEDIA_ERROR",
  error,
});

const getAllMediaStart = (): Action => ({
  type: "GET_ALL_MEDIA_START",
});

const getMediaGroupsSuccess = (groups: PictureGroup[]): Action => ({
  type: "GET_ALL_MEDIA_GROUPS_SUCCESS",
  groups,
});

const getMediaGroupsError = (error: Error): Action => ({
  type: "GET_ALL_MEDIA_GROUPS_ERROR",
  error,
});

const getMediaGroupsStart = (): Action => ({
  type: "GET_ALL_MEDIA_GROUPS_START",
});

const getMediaGroupSuccess = (
  pictures: Picture[],
  groupId: number,
): Action => ({
  type: "GET_MEDIA_GROUP_SUCCESS",
  pictures,
  groupId,
});

const getMediaGroupError = (error: Error): Action => ({
  type: "GET_MEDIA_GROUP_ERROR",
  error,
});

const getMediaGroupStart = (groupId: number): Action => ({
  type: "GET_MEDIA_GROUP_START",
  groupId,
});

export const uploadMedia =
  (siteId: string, file: File): ThunkAction<Promise<void>> =>
  async (dispatch) => {
    const formData = new FormData();
    formData.append("file", file);
    dispatch({ type: "UPLOAD_MEDIA_START" });

    try {
      const { data } = await fetch({ dispatch }).post<Picture>(
        `sites/${siteId}/logos`,
        formData,
      );
      dispatch({ type: "UPLOAD_MEDIA_SUCCESS", picture: data });
    } catch (error) {
      error instanceof Error && dispatch({ type: "UPLOAD_MEDIA_ERROR", error });
      dispatch(
        showAlert("Beim Hochladen der Datei ist ein Fehler aufgetreten"),
      );
      throw error;
    }
  };

export const deleteMedia =
  (siteId: string, id: string): ThunkAction<Promise<void>> =>
  async (dispatch, getState) => {
    dispatch({ type: "DELETE_MEDIA_START" });

    const state = getState();
    const picture = state.mediaLibrary.pictures[id];

    try {
      const pathParts = picture.url.split("/");
      const filename = pathParts[pathParts.length - 1];

      await fetch({ dispatch }).delete(`sites/${siteId}/logos/${filename}`);
      dispatch({ type: "DELETE_MEDIA_SUCCESS", id });
    } catch (error) {
      error instanceof Error && dispatch({ type: "DELETE_MEDIA_ERROR", error });
      dispatch(
        showAlert("Beim Hochladen der Datei ist ein Fehler aufgetreten"),
      );
      throw error;
    }
  };

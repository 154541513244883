import { FunctionComponent } from "react";
import { connect, ConnectedProps, MapStateToProps } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { patchPage } from "../actions/Pages.js";
import { getActiveSite } from "../selectors/sites.js";
import {
  Language,
  PageSettingsOnSave,
  PageTranslationPostRequest,
  PageTranslationType,
  StoreState,
  ThunkDispatch,
} from "../types/index.js";
import {
  getPageTranslationType,
  getTranslatedPage,
  getURL,
} from "../utils/utils.js";
import PageSettings from "./PageSettings.js";

type Props = RouteComponentProps<{
  siteId: string;
  pageId: string;
  languageId: Language;
}>;

interface StateProps {
  settings: PageTranslationPostRequest;
  languageId: Language;
  title: string;
  closeLink: string;
  popUpModuleId: string | null;
  hasGlobalPopUpModule: boolean;
}

type DispatchProps = {
  onSave: PageSettingsOnSave;
};

type ReduxProps = ConnectedProps<typeof connector>;

const getTitle = (pageTranslationType: PageTranslationType) => {
  switch (pageTranslationType) {
    case "page":
      return "Seite bearbeiten";
    case "redirect":
      return "Link bearbeiten";
    case "folder":
      return "Ordner bearbeiten";
  }
};

const PageTranslationUpdate: FunctionComponent<Props & ReduxProps> = ({
  settings,
  languageId,
  title,
  closeLink,
  onSave,
  popUpModuleId,
  hasGlobalPopUpModule,
}) => (
  <PageSettings
    title={title}
    settings={settings}
    languageId={languageId}
    onSave={onSave}
    closeLink={closeLink}
    pageTranslationType={getPageTranslationType(settings.link)}
    pagesLimitReached={false}
    popUpModuleId={popUpModuleId}
    hasGlobalPopUpModule={hasGlobalPopUpModule}
  />
);

const mapStateToProps: MapStateToProps<StateProps, Props, StoreState> = (
  { pages, sites },
  {
    match: {
      params: { siteId, pageId, languageId },
    },
  },
): StateProps => {
  const { translation, popUpModuleId } = getTranslatedPage(
    pages,
    pageId,
    languageId,
    true,
  );
  const pageTranslationType = getPageTranslationType(translation.link);
  const title = getTitle(pageTranslationType);
  const closeLink = getURL(siteId, "pages", pageId, translation.languageId);
  const { createdAt, updatedAt, ...settings } = translation;

  return {
    settings,
    languageId,
    title,
    closeLink,
    popUpModuleId,
    hasGlobalPopUpModule: getActiveSite(sites).popUpModuleId !== null,
  };
};

// There is a typings bug in bind action creator
// https://github.com/piotrwitek/react-redux-typescript-guide/issues/6
const mapDispatchToProps = (
  dispatch: ThunkDispatch,
  {
    history,
    match: {
      params: { siteId, pageId, languageId },
    },
  }: Props,
): DispatchProps => ({
  onSave: async ({ translation, popUpModuleId }) => {
    await dispatch(
      patchPage(siteId, pageId, {
        popUpModuleId,
        translations: {
          [languageId]: translation,
        },
      }),
    );
    history.push(getURL(siteId, "pages", pageId, languageId));
  },
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default withRouter(connector(PageTranslationUpdate));

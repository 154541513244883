import { FunctionComponent, useEffect } from "react";
import { ConnectedProps, MapStateToProps, connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { getModules, setModuleSettings } from "../actions/Modules.js";
import { defaultImportantImagePoint } from "../reducers/modules/byId.js";
import {
  AspectRatio,
  ImageCropParams,
  ImageGalleryModuleSettings,
  ImageModuleSettings,
  Language,
  Module,
  ModuleType,
  Modules,
  Picture,
  Point,
  StoreState,
  ThunkDispatch,
} from "../types/index.js";
import {
  getGalleryMediaAspectRatio,
  getModulesByParentId,
  getURL,
  isImageModule,
} from "../utils/utils.js";
import ImageCrop from "./ImageCrop.js";
import ImagePointSelection from "./ImagePointSelection.js";

type Props = RouteComponentProps<{
  siteId: string;
  pageId: string;
  languageId: Language;
  moduleId: string;
  mode: "point-selection" | "crop";
}>;

type ReduxProps = ConnectedProps<typeof connector>;

type StateProps = {
  picture?: Picture;
  imageModule?: Module<ImageModuleSettings>;
  parentModuleType?: ModuleType | undefined;
  targetAspectRatio?: number | undefined;
};

const ImageDetailSettings: FunctionComponent<Props & ReduxProps> = (props) => {
  const {
    history,
    match: {
      params: { siteId, pageId, mode },
    },
    getModules,
    imageModule,
    picture,
    setPoint,
    setCrop,
    targetAspectRatio,
  } = props;
  const closeURL = getCloseURL(props);

  useEffect(() => {
    // If this module was not yet fetched from the server, fetch it
    !imageModule && getModules({ siteId, pageId, forceLoad: false });
  }, []);

  if (!picture || !imageModule) return null;

  return (
    <>
      {mode === "crop" && (
        <ImageCrop
          closeURL={closeURL}
          picture={picture}
          initialCrop={imageModule.settings.crop}
          onSubmit={(crop) => {
            setCrop(crop);
            history.push(closeURL);
          }}
        />
      )}

      {mode === "point-selection" && (
        <ImagePointSelection
          crop={imageModule.settings.crop}
          closeURL={closeURL}
          picture={picture}
          initialPoint={imageModule.settings.importantPoint}
          targetAspectRatio={targetAspectRatio}
          onSubmit={(point) => {
            setPoint(point);
            history.push(closeURL);
          }}
        />
      )}
    </>
  );
};

const mapStateToProps: MapStateToProps<StateProps, Props, StoreState> = (
  { modules, mediaLibrary: { pictures } },
  {
    match: {
      params: { pageId, moduleId },
    },
  },
): StateProps => {
  const module = modules.byId[moduleId];

  if (!module || !isImageModule(module)) {
    return {};
  }

  const imageModule = module;
  const { pictureId } = imageModule.settings;
  const picture = pictures[pictureId];

  // If pictures are not yet loaded
  if (!picture) {
    return {};
  }

  const { parentId } = imageModule;
  const parentModuleType = parentId ? modules.byId[parentId]?.type : undefined;
  const targetAspectRatio = getAspectRatio({
    module: imageModule,
    imageModuleId: imageModule.id,
    modules,
    pageId,
  });

  return {
    imageModule,
    picture,
    parentModuleType,
    targetAspectRatio,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch,
  {
    match: {
      params: { languageId, siteId, moduleId },
    },
  }: Props,
) => ({
  setCrop: (crop: ImageCropParams) =>
    dispatch(
      setModuleSettings<ImageModuleSettings>(siteId, languageId, moduleId, {
        global: { crop, importantPoint: defaultImportantImagePoint },
      }),
    ),
  setPoint: (importantPoint: Point) =>
    dispatch(
      setModuleSettings<ImageModuleSettings>(siteId, languageId, moduleId, {
        global: { importantPoint },
      }),
    ),
  getModules: (...params: Parameters<typeof getModules>) =>
    dispatch(getModules(...params)),
});

const getCloseURL = ({
  match: {
    params: { siteId, pageId, languageId, moduleId },
  },
  parentModuleType,
  imageModule,
}: Props & StateProps) => {
  const baseUrl = getURL(siteId, "pages", pageId, languageId, "modules");

  if (parentModuleType && imageModule && imageModule.parentId) {
    const urlWithModuleId = getURL(baseUrl, imageModule.parentId);
    switch (parentModuleType) {
      case "SpecialsModule":
        return getURL(urlWithModuleId, "specials");
      case "RoomsModule":
        return getURL(urlWithModuleId, "rooms");
    }
  }

  return getURL(siteId, "pages", pageId, languageId, "modules", moduleId);
};

/**
 * Get aspectRatio of a module by climbing up recursively the tree.
 */
const getAspectRatio = ({
  module,
  imageModuleId,
  modules,
  pageId,
}: {
  module: Module;
  imageModuleId: string;
  modules: Modules;
  pageId: string;
}): number | undefined => {
  if (isImageGalleryModule(module)) {
    const imageModuleIds = getModulesByParentId<ImageModuleSettings>(
      modules,
      module.id,
      pageId,
      "ImageModule",
    ).map(({ id }) => id);
    const index = imageModuleIds.indexOf(imageModuleId);
    if (index > -1) {
      return getGalleryMediaAspectRatio(index);
    }
  }

  if (hasImagesAspectRatio(module)) {
    return module.settings.mediaAspectRatio;
  }

  if (hasLayoutVariant(module)) {
    return undefined;
  }

  const { parentId } = module;

  if (!parentId) {
    return undefined;
  }

  return getAspectRatio({
    module: modules.byId[parentId],
    imageModuleId,
    modules,
    pageId,
  });
};

const isImageGalleryModule = (
  module: Module,
): module is Module<ImageGalleryModuleSettings> =>
  module.type === "ImageGalleryModule";

interface ModuleSettingsWithAspectRatio {
  global: {
    mediaAspectRatio: AspectRatio;
  };
  language: {};
}

const hasImagesAspectRatio = (
  module: Module,
): module is Module<ModuleSettingsWithAspectRatio> =>
  (module as Module<ModuleSettingsWithAspectRatio>).settings
    .mediaAspectRatio !== undefined;

interface ModuleSettingsWithLayoutVariant {
  global: {
    layoutVariant: string;
  };
  language: {};
}

const hasLayoutVariant = (
  module: Module,
): module is Module<ModuleSettingsWithLayoutVariant> =>
  (module as Module<ModuleSettingsWithLayoutVariant>).settings.layoutVariant !==
  undefined;

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(ImageDetailSettings);

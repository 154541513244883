import { debounce } from "throttle-debounce";
import { PagePatch, PagePost } from "../../server/types/index.js";
import {
  DragPageActionParams,
  DragPageParams,
  Language,
  Page,
  PagePostRequest,
  PageRequest,
  PageState,
  PageTranslation,
  PageTranslationPostRequest,
  ThunkAction,
  ThunkDispatch,
} from "../types/index.js";
import {
  PageNotFoundError,
  fetch,
  getTranslationFallbackLanguage,
  isItemUntranslated,
} from "../utils/utils.js";
import { showAlert } from "./Alerts.js";

export type Action =
  | { type: "GET_PAGES_START" }
  | { type: "GET_PAGES_SUCCESS"; pages: Page[] }
  | { type: "POST_PAGE_SUCCESS"; page: Page }
  | {
      type: "PATCH_PAGE_START";
      pageId: string;
      pageRequest: PageRequest;
    }
  | {
      type: "PATCH_PAGE_SUCCESS";
      page: Page;
    }
  | {
      type: "PATCH_PAGE_ERROR";
      prevState: PageState;
    }
  | {
      type: "DRAG_PAGE";
      sourcePageId: string;
      targetPageId: string;
      insertBefore: boolean;
      isNewSubtree: boolean;
    }
  | {
      type: "POST_PAGE_TRANSLATION_START";
      pageId: string;
      languageId: Language;
      sourceLanguageId: Language;
    }
  | {
      type: "POST_PAGE_TRANSLATION_SUCCESS";
      pageId: string;
      sourceLanguageId: Language;
      pageTranslation: PageTranslation;
    }
  | {
      type: "DELETE_PAGE_TRANSLATION_START";
      pageId: string;
      languageId: Language;
    }
  | { type: "DELETE_PAGE_TRANSLATION_ERROR"; prevState: PageState };

export const getPages = (siteId: string): ThunkAction<Promise<void>> => {
  return async (dispatch, getState) => {
    if (getState().loadStates.pages !== "unloaded") {
      return Promise.resolve();
    }

    dispatch(getPagesStart());

    try {
      const { data: pages } = await fetch({ dispatch }).get<Page[]>(
        `sites/${siteId}/pages`,
      );
      dispatch(getPagesSuccess(pages));
    } catch (error) {
      dispatch(showAlert("Der Seitenbaum konnte nicht geladen werden!"));
      throw error;
    }
  };
};

const getPagesStart = (): Action => ({
  type: "GET_PAGES_START",
});

export const getPagesSuccess = (pages: Page[]): Action => ({
  type: "GET_PAGES_SUCCESS",
  pages,
});

export const postPage =
  (siteId: string, request: PagePostRequest): ThunkAction<Promise<Page>> =>
  async (dispatch) => {
    const { translation, ...rest } = request;
    const data: PagePost = {
      ...rest,
      translations: {
        [translation.languageId]: translation,
      },
    };
    try {
      const { data: page } = await fetch({ dispatch }).post<Page>(
        `sites/${siteId}/pages`,
        data,
      );

      dispatch(postPageSuccess(page));
      return Promise.resolve(page);
    } catch (error) {
      dispatch(
        showAlert("Beim Erstellen der Seite ist ein Fehler aufgetreten!"),
      );
      throw error;
    }
  };

export const postPageSuccess = (page: Page): Action => ({
  type: "POST_PAGE_SUCCESS",
  page,
});

export const patchPage =
  (
    siteId: string,
    pageId: string,
    pageProps: PageRequest,
    debounce = false,
  ): ThunkAction<Promise<void>> =>
  async (dispatch, getState) => {
    const prevState = getState().pages;
    dispatch(patchPageStart(pageId, pageProps));
    try {
      const requestFunc = debounce
        ? debouncedPatchPageRequest
        : patchPageRequest;

      await requestFunc({
        dispatch,
        siteId,
        pageId,
        pageProps,
      });
    } catch (error) {
      dispatch(patchPageError(prevState));
      dispatch(
        showAlert("Die Seiteneinstellungen konnten nicht aktualisiert werden!"),
      );
      throw error;
    }
  };

const patchPageStart = (pageId: string, pageRequest: PageRequest): Action => ({
  type: "PATCH_PAGE_START",
  pageId,
  pageRequest,
});

const patchPageSuccess = (page: Page): Action => ({
  type: "PATCH_PAGE_SUCCESS",
  page,
});

const patchPageError = (prevState: PageState): Action => ({
  type: "PATCH_PAGE_ERROR",
  prevState,
});

export const dragPage = (params: DragPageActionParams): ThunkAction<void> => {
  return async (dispatch) => {
    const { siteId, ...rest } = params;
    const drag: DragPageParams = rest;
    const { sourcePageId } = drag;
    dispatch<Action>({
      type: "DRAG_PAGE",
      ...drag,
    });

    try {
      return fetch({ dispatch }).post(
        `sites/${siteId}/pages/${sourcePageId}/drag`,
        drag,
      );
    } catch (error) {
      showAlert("Die Seite konnte nicht verschoben werden!");
      throw error;
    }
  };
};

export const postPageTranslation =
  (
    siteId: string,
    pageId: string,
    languageId: Language,
    form: PageTranslationPostRequest,
  ): ThunkAction<Promise<PageTranslation>> =>
  async (dispatch, getState) => {
    const { pages } = getState();
    const page = pages.byId[pageId];
    if (!page) throw new PageNotFoundError(pageId);
    const sourceLanguageId = getTranslationFallbackLanguage(page, languageId);

    const requestData: PagePatch = {
      translations: {
        [languageId]: form,
      },
    };

    dispatch(postPageTranslationStart(pageId, languageId, sourceLanguageId));

    try {
      const res = await fetch({ dispatch }).patch(
        `sites/${siteId}/pages/${pageId}`,
        requestData,
      );
      const page: Page = res.data;
      const pageTranslation = page.translations[languageId];
      if (!pageTranslation) {
        throw Error(
          "The posted page translation was not returned from the server.",
        );
      }

      dispatch(
        postPageTranslationSuccess(pageId, sourceLanguageId, pageTranslation),
      );
      return Promise.resolve(pageTranslation);
    } catch (error) {
      showAlert(
        `Die ${languageId.toUpperCase()} Übersetzung konnte nicht zur Seite hinzugefügt werden!`,
      );
      throw error;
    }
  };

const postPageTranslationStart = (
  pageId: string,
  languageId: Language,
  sourceLanguageId: Language,
): Action => ({
  type: "POST_PAGE_TRANSLATION_START",
  pageId,
  languageId,
  sourceLanguageId,
});

export const postPageTranslationSuccess = (
  pageId: string,
  sourceLanguageId: Language,
  pageTranslation: PageTranslation,
): Action => ({
  type: "POST_PAGE_TRANSLATION_SUCCESS",
  pageId,
  sourceLanguageId,
  pageTranslation,
});

export const deletePageTranslation = (
  pageId: string,
  languageId: Language,
): ThunkAction<void> => {
  return async (dispatch, getState) => {
    const { pages } = getState();
    const previousPages = pages;
    const page = pages.byId[pageId];
    if (!page) throw new PageNotFoundError(pageId);
    const isUntranslated = isItemUntranslated(page);

    dispatch(deletePageTranslationStart(pageId, languageId));

    const deleteRequest: PagePatch = {
      translations: {
        [languageId]: null,
      },
    };

    const url = `sites/${page.siteId}/pages/${pageId}`;
    try {
      return isUntranslated
        ? fetch({ dispatch }).delete(url)
        : fetch({ dispatch }).patch(url, deleteRequest);
    } catch (error) {
      dispatch(deletePageTranslationError(previousPages));
      dispatch(
        showAlert(
          "Beim Löschen der Seiten-Übersetzung ist ein Fehler aufgetreten!",
        ),
      );
      throw error;
    }
  };
};

const deletePageTranslationStart = (
  pageId: string,
  languageId: Language,
): Action => ({
  type: "DELETE_PAGE_TRANSLATION_START",
  pageId,
  languageId,
});

const deletePageTranslationError = (prevState: PageState): Action => ({
  type: "DELETE_PAGE_TRANSLATION_ERROR",
  prevState,
});

const patchPageRequest = async ({
  dispatch,
  siteId,
  pageId,
  pageProps,
}: {
  dispatch: ThunkDispatch;
  siteId: string;
  pageId: string;
  pageProps: PageRequest;
}): Promise<Page> => {
  const { data: page } = await fetch({ dispatch }).patch<Page>(
    `sites/${siteId}/pages/${pageId}`,
    pageProps,
  );
  dispatch(patchPageSuccess(page));
  return page;
};

const debouncedPatchPageRequest = debounce(2000, patchPageRequest);

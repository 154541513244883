import { FunctionComponent, useState } from "react";
import {
  PartnerLinks,
  TranslatedPartnerLogo,
  type PartnerLogoSelectionForm,
} from "../types/index.js";
import { keys } from "../utils/utils.js";
import MediaLibraryImage from "./MediaLibraryImage.js";

interface Props {
  logos: TranslatedPartnerLogo[];
  initialValues: PartnerLogoSelectionForm;
  onSubmit: (form: PartnerLogoSelectionForm) => void;
}

const PartnerLogoSelectionForm: FunctionComponent<Props> = ({
  initialValues,
  logos,
  onSubmit,
}) => {
  const [selectedLogoId, setSelectedLogoId] = useState(
    initialValues.selectedLogoId,
  );
  const [partnerLinks, setPartnerLinks] = useState(initialValues.partnerLinks);

  return (
    <form
      className="Form"
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit({
          selectedLogoId,
          partnerLinks,
        });
      }}
    >
      <section className="Sidebar__Section">
        <h3 className="Sidebar__Section__Title">Logo-Varianten</h3>
        <div className="MediaLibrary__Images">
          {logos.map(({ id, translation: { url }, background }) => (
            <MediaLibraryImage
              key={id}
              isSelected={selectedLogoId === id}
              url={url}
              category="logo"
              onSelection={() => setSelectedLogoId(id)}
              background={background}
            />
          ))}
        </div>
      </section>
      <section className="Sidebar__Section">
        <h3 className="Sidebar__Section__Title">Logo-Link</h3>

        {keys(partnerLinks).map((languageId) => {
          const currentPartnerLink = partnerLinks[languageId];
          if (!currentPartnerLink) return null;
          const htmlId = "logo-url-" + languageId;
          return (
            <div className="Form__Field" key={languageId}>
              <div className="Form__InlineFieldContent">
                <label className="Form__Content" htmlFor={htmlId}>
                  {currentPartnerLink.hostname}
                </label>
                <input
                  id={htmlId}
                  type="text"
                  required
                  name={languageId}
                  value={currentPartnerLink.url}
                  onChange={(e) => {
                    const updatedPartnerLinks: PartnerLinks = {
                      ...partnerLinks,
                      [languageId]: {
                        ...currentPartnerLink,
                        url: e.target.value,
                      },
                    };
                    setPartnerLinks(updatedPartnerLinks);
                  }}
                />
              </div>
              <div className="Form__Label">
                <label>{languageId}</label>
              </div>
            </div>
          );
        })}
      </section>

      <button type="submit" className="Btn Btn--action">
        Speichern
      </button>
    </form>
  );
};

export default PartnerLogoSelectionForm;

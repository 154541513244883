import { useDraggable } from "@dnd-kit/core";
import ClassNames from "classnames";
import { FunctionComponent } from "react";
import { ConnectedProps, MapStateToProps, connect } from "react-redux";
import { patchPage } from "../actions/Pages.js";
import { getActiveSite } from "../selectors/sites.js";
import {
  Language,
  PageTranslationType,
  StoreState,
  TranslatedPage,
} from "../types/index.js";
import {
  getFallbackLanguage,
  getPageIcon,
  getPageTranslationType,
  getTranslatedPageWithFallback,
} from "../utils/utils.js";
import Icon from "./Icon.js";
import PageTreeItemDropTarget from "./PageTreeItemDropTarget.js";

interface Props {
  pageId: string;
  languageId: Language;
  matchedLanguageId: Language;
  languageFallback: boolean;
  index: number;
  onSelect: (pageId: string, fallbackLanguageId: Language) => void;
  activeId?: string;
  enableDrag: boolean;
  hasCustomOnPageSelect: boolean;
  allowFolderSelection: boolean;
  showPopUpIcon: boolean;
}

interface StateProps {
  page: TranslatedPage;
  children: string[];
  fallbackSettingApplied: boolean;
  hasGlobalPopUpModule: boolean;
}

type ReduxProps = ConnectedProps<typeof connector>;

type AllProps = Props & ReduxProps;

const getButtonAttributes = ({
  type,
  isEnabled,
  hasCustomOnPageSelect,
  allowFolderSelection,
}: {
  type: PageTranslationType;
  isEnabled: boolean;
  hasCustomOnPageSelect: boolean;
  allowFolderSelection: boolean;
}): { title: string | undefined; disabled: boolean } => {
  if (!isEnabled && hasCustomOnPageSelect) {
    return {
      title: "Deaktivierte Seiten können nicht ausgewählt werden.",
      disabled: true,
    };
  }

  if (type === "folder" && !allowFolderSelection) {
    return {
      title: "Ordner können hier nicht ausgewählt werden.",
      disabled: true,
    };
  }

  return {
    title: undefined,
    disabled: false,
  };
};

const PageTreeItem: FunctionComponent<AllProps> = ({
  page,
  page: {
    translation: { title, isVisible, link, languageId: fallbackLanguageId },
    isEnabled,
  },
  languageId,
  patchPage,
  children,
  index,
  onSelect,
  matchedLanguageId,
  activeId,
  languageFallback,
  enableDrag,
  fallbackSettingApplied,
  hasCustomOnPageSelect,
  allowFolderSelection,
  showPopUpIcon,
  hasGlobalPopUpModule,
}) => {
  const type = getPageTranslationType(link);
  const isPageUntranslated = languageId !== fallbackLanguageId;
  const isActive = activeId === page.id && matchedLanguageId === languageId;
  const pageClickable = !isPageUntranslated;
  const isFirst = index === 0;
  const isMainPage = enableDrag && page.parentId === null && isFirst;

  const { attributes, listeners, setNodeRef, isDragging } = useDraggable({
    id: page.id,
    disabled: !enableDrag,
  });

  if (!languageFallback && isPageUntranslated) return null;

  const pageClassNames = ClassNames("PageTree__Item", {
    "PageTree__Item--invisible": !isVisible || !isEnabled,
    "PageTree__Item--dragging": isDragging,
  });

  const pageLinkClassNames = ClassNames("PageTree__Link", {
    "PageTree__Link--active": isActive,
    "PageTree__Link--no-hover": !pageClickable,
  });

  const hasChildren = children.length > 0;

  const buttonAttributes = getButtonAttributes({
    type,
    allowFolderSelection,
    hasCustomOnPageSelect,
    isEnabled,
  });

  return (
    <li
      ref={setNodeRef}
      className={pageClassNames}
      {...listeners}
      {...attributes}
    >
      {enableDrag && (
        <>
          {isFirst && (
            <PageTreeItemDropTarget
              isFirst={isFirst}
              isNewSubtree={false}
              pageId={page.id}
            />
          )}
          <PageTreeItemDropTarget
            isFirst={false}
            isNewSubtree={false}
            pageId={page.id}
          />
        </>
      )}

      <>
        <div
          className={ClassNames("PageTree__Item__Inner", {
            PageTree__Drag: enableDrag,
            "PageTree__Item__Inner--untranslated":
              !fallbackSettingApplied && isPageUntranslated,
          })}
        >
          {hasChildren && (
            <button
              className="PageTree__Folding PageTree__Icon Btn--bare"
              type="button"
              onClick={() =>
                patchPage(page.siteId, page.id, {
                  isExpanded: !page.isExpanded,
                })
              }
            >
              <Icon glyph={page.isExpanded ? "arrow-down" : "arrow-right"} />
            </button>
          )}

          <Icon
            glyph={getPageIcon({ isMainPage, type, isEnabled })}
            className="PageTree__Symbol PageTree__Icon"
          />

          {showPopUpIcon && hasGlobalPopUpModule && isMainPage && (
            <Icon
              className="PageTree__Icon PageTree__Icon--active"
              glyph="pop-up"
            />
          )}

          <button
            className={pageLinkClassNames}
            type="button"
            title={buttonAttributes.title}
            disabled={buttonAttributes.disabled}
            onClick={() => onSelect(page.id, fallbackLanguageId)}
          >
            {title}
          </button>

          {showPopUpIcon && page.popUpModuleId !== null && (
            <Icon
              className="PageTree__Icon PageTree__Icon--right PageTree__Icon--active"
              glyph="pop-up"
            />
          )}

          {(isPageUntranslated || fallbackSettingApplied) && (
            <span
              className={ClassNames("PageTree__Language", {
                "PageTree__Language--negative": fallbackSettingApplied,
              })}
            >
              {fallbackLanguageId}
            </span>
          )}
        </div>
      </>
      <ul className="PageTree">
        {hasChildren &&
          page.isExpanded &&
          children.map((pageId, index) => (
            <ConnectedPageTreeItem
              key={pageId}
              index={index}
              pageId={pageId}
              languageId={languageId}
              languageFallback={languageFallback}
              matchedLanguageId={matchedLanguageId}
              activeId={activeId}
              onSelect={onSelect}
              hasCustomOnPageSelect={hasCustomOnPageSelect}
              enableDrag={enableDrag}
              allowFolderSelection={allowFolderSelection}
              showPopUpIcon={showPopUpIcon}
            />
          ))}
        {enableDrag && !hasChildren && (
          <li className="PageTree__Item">
            <PageTreeItemDropTarget
              isFirst={false}
              isNewSubtree={true}
              pageId={page.id}
            />
          </li>
        )}
      </ul>
    </li>
  );
};

const mapStateToProps: MapStateToProps<StateProps, Props, StoreState> = (
  { pages, sites },
  { pageId, languageId },
): StateProps => {
  const site = getActiveSite(sites);
  const fallbackLanguageId = getFallbackLanguage(site, languageId);
  const { translations } = pages.byId[pageId];
  const fallbackSettingApplied =
    !(languageId in translations) &&
    !!fallbackLanguageId &&
    fallbackLanguageId in translations;

  const page = getTranslatedPageWithFallback({
    pages,
    languageId,
    pageId,
    fallbackLanguageId,
    fallbackToFirst: true,
  });
  const children = pages.byParentId[pageId] || [];

  return {
    page,
    children,
    fallbackSettingApplied,
    hasGlobalPopUpModule: site.popUpModuleId !== null,
  };
};

const mapDispatchToProps = {
  patchPage,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

const ConnectedPageTreeItem = connector(PageTreeItem);

export default ConnectedPageTreeItem;

import { FunctionComponent, useEffect } from "react";
import { ConnectedProps, MapStateToProps, connect } from "react-redux";
import {
  getRevisions,
  patchRevision,
  resetRevision,
} from "../actions/Revisions.js";
import { getActiveSite } from "../selectors/sites.js";
import { Revision, StoreState } from "../types/index.js";
import RevisionsList from "./RevisionsList.js";
import Sidebar from "./Sidebar.js";
import Spinner from "./Spinner.js";

type ReduxProps = ConnectedProps<typeof connector>;

interface StateProps {
  revisions: Revision[];
  favorites: Revision[];
  siteId: string;
  isLoading: boolean;
  maxFavoritesReached: boolean;
}

const Revisions: FunctionComponent<ReduxProps> = ({
  isLoading,
  revisions,
  favorites,
  maxFavoritesReached,
  getRevisions,
  siteId,
  patchRevision,
  resetRevision,
}) => {
  useEffect(() => {
    getRevisions(siteId);
  }, []);

  const setFavoriteStatus = (revisionId: string, newStatus: boolean) =>
    patchRevision(siteId, revisionId, newStatus);

  const onResetRevision = (revisionId: string) =>
    resetRevision(siteId, revisionId);

  return (
    <Sidebar className="Revisions Sidebar--full" heading="Versionsverlauf">
      {isLoading && <Spinner />}

      {!isLoading && (
        <>
          <RevisionsList
            title="Letzte veröffentlichte Versionen"
            isFavoriteList={false}
            onRevert={onResetRevision}
            revisions={revisions}
            onToggleFavoriteStatus={setFavoriteStatus}
            maxFavoritesReached={maxFavoritesReached}
          />

          <RevisionsList
            title={`Favoriten (${favorites.length} von 3)`}
            isFavoriteList={true}
            onRevert={onResetRevision}
            revisions={favorites}
            onToggleFavoriteStatus={setFavoriteStatus}
            maxFavoritesReached={maxFavoritesReached}
          />
        </>
      )}
    </Sidebar>
  );
};

const mapDispatchToProps = {
  patchRevision,
  getRevisions,
  resetRevision,
};

const mapStateToProps: MapStateToProps<StateProps, {}, StoreState> = ({
  sites,
  revisions,
  loadStates,
}): StateProps => {
  const site = getActiveSite(sites);

  const revisionsList: Revision[] = revisions.allIds.map(
    (id) => revisions.byId[id],
  );

  const favorites: Revision[] = revisionsList.filter(
    ({ isFavorite }) => isFavorite,
  );

  const maxFavoritesReached = favorites.length >= 3;

  const isLoading = loadStates.revisions === "loading";

  return {
    siteId: site.id,
    revisions: revisionsList,
    favorites,
    isLoading,
    maxFavoritesReached,
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(Revisions);

import { FunctionComponent, useEffect, useRef, useState } from "react";
import { ConnectedProps, MapStateToProps, connect } from "react-redux";
import { Link, RouteComponentProps } from "react-router-dom";
import { setModuleSetting } from "../actions/Modules.js";
import { getActiveSite } from "../selectors/sites.js";
import {
  Language,
  Module,
  PartialModuleSettings,
  SeparatorModuleSettings,
  StoreState,
  ThunkDispatch,
  TranslatedModule,
} from "../types/index.js";
import {
  filterItemWithTitle,
  getTranslatedModule,
  getURL,
} from "../utils/utils.js";
import FormInfo from "./FormInfo.js";
import Icon from "./Icon.js";
import IconLibraryItem from "./IconLibraryItem.js";
import { getShowIconForAccommodation, icons } from "./IconSelect.js";
import Sidebar from "./Sidebar.js";

type Props = RouteComponentProps<{
  siteId: string;
  pageId: string;
  moduleId: string;
  languageId: Language;
}>;

interface StateProps {
  translatedModule: TranslatedModule<SeparatorModuleSettings> | undefined;
  accommodationId: number;
}

type ReduxProps = ConnectedProps<typeof connector>;

const IconLibrary: FunctionComponent<Props & ReduxProps> = ({
  setModuleSetting,
  translatedModule,
  match: {
    params: { languageId, moduleId, pageId, siteId },
  },
  history,
  accommodationId,
}) => {
  const [filterText, setFilterText] = useState("");
  const rafId = useRef<number>();

  useEffect(() => {
    return () => {
      rafId.current !== undefined && window.cancelAnimationFrame(rafId.current);
    };
  });

  if (!translatedModule) return null;

  const closeLink = getURL(
    siteId,
    "pages",
    pageId,
    languageId,
    "modules",
    moduleId,
  );

  const filteredIcons = icons.filter(
    (icon) =>
      getShowIconForAccommodation(icon, accommodationId) &&
      filterItemWithTitle(icon, filterText),
  );

  return (
    <Sidebar
      className="MediaLibrary"
      heading="Icon-Bibliothek"
      closeLink={closeLink}
    >
      <form className="Form" onSubmit={(e) => e.preventDefault()}>
        <div className="Form__Field">
          <input
            type="search"
            onChange={({ target: { value } }) => {
              rafId.current = window.requestAnimationFrame(() =>
                setFilterText(value),
              );
            }}
          />
          <div className="Form__Label">
            <label>Suche</label>
          </div>
          {!filteredIcons.length && <FormInfo>Keine Icons gefunden!</FormInfo>}
        </div>
      </form>
      <div className="MediaLibrary__Images">
        {filteredIcons.map(({ title, id }) => {
          return (
            <IconLibraryItem
              id={id}
              key={id}
              isSelected={translatedModule.settings.icon === id}
              onSelection={() => {
                setModuleSetting(translatedModule, {
                  global: {
                    icon: id,
                  },
                });
                history.push(closeLink);
              }}
              title={title}
            />
          );
        })}
      </div>
      <Link className="Btn MediaLibrary__Button" to={closeLink}>
        <Icon glyph="arrow-left" /> Zurück
      </Link>
    </Sidebar>
  );
};

const mapStateToProps: MapStateToProps<StateProps, Props, StoreState> = (
  { modules, sites },
  {
    match: {
      params: { moduleId, languageId },
    },
  },
): StateProps => {
  const module = modules.byId[moduleId] as
    | Module<SeparatorModuleSettings>
    | undefined;
  const translatedModule =
    module && getTranslatedModule<SeparatorModuleSettings>(module, languageId);

  return {
    translatedModule,
    accommodationId: getActiveSite(sites).accommodation.id,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch) => {
  return {
    setModuleSetting: (
      translatedModule: TranslatedModule<SeparatorModuleSettings>,
      settings: PartialModuleSettings<SeparatorModuleSettings>,
    ) =>
      dispatch(
        setModuleSetting<SeparatorModuleSettings>(translatedModule, settings),
      ),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(IconLibrary);

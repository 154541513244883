import ClassNames from "classnames";
import { FunctionComponent, MouseEvent, ReactNode } from "react";
import { MapStateToProps, connect } from "react-redux";
import { Link } from "react-router-dom";
import { getActiveSite } from "../selectors/sites.js";
import {
  IconName,
  IssueItemType,
  IssueSeverity,
  IssueType,
  LinkData,
  PageState,
  StoreState,
} from "../types/index.js";
import {
  formatNumericDate,
  getModuleIcon,
  getPageIcon,
  getURL,
  isExternalLink,
  isModuleLink,
  isPageLink,
} from "../utils/utils.js";
import Icon from "./Icon.js";

type Props = IssueItemType & {
  onArchive: (id: string, archive: boolean) => void;
  onLinkClick: undefined | ((event: MouseEvent<HTMLAnchorElement>) => void);
};

type IssueLink = Partial<{
  href: string;
  icon: IconName;
  title: ReactNode;
}>;

interface StateProps {
  issueLink: IssueLink | undefined;
  isExternal: boolean;
}

const iconMap: { [key in IssueSeverity]: IconName } = {
  critical: "error",
  error: "error",
  warning: "warning",
};

const checkIsCritical = (severity: IssueSeverity) => severity === "critical";

const IssueItem: FunctionComponent<Props & StateProps> = ({
  id: issueId,
  description,
  firstSeen,
  issueLink,
  onArchive,
  isArchived,
  severity,
  onLinkClick,
  resolvedAt,
  isExternal,
}) => {
  const linkContent = issueLink?.href ? (
    <>
      {issueLink.icon && <Icon glyph={issueLink.icon} />}{" "}
      {issueLink.title || "Link"}
    </>
  ) : null;

  return (
    <tr className="IssueItem">
      <td
        className={ClassNames("IssueItem__Status", {
          "IssueItem__Status--archive": isArchived,
          "IssueItem__Status--warning": !isArchived,
        })}
      >
        <Icon glyph={isArchived ? "archive" : iconMap[severity]} />
      </td>
      <td className="IssueItem__Url">{description}</td>
      <td>
        {!isExternal && issueLink?.href && (
          <Link
            rel="noopener"
            target="_blank"
            className="Btn"
            to={issueLink.href}
            onClick={onLinkClick}
          >
            {linkContent}
          </Link>
        )}

        {isExternal && issueLink?.href && (
          <a
            rel="noopener"
            target="_blank"
            className="Btn"
            href={issueLink.href}
          >
            {linkContent}
          </a>
        )}
      </td>
      <td>{formatNumericDate(firstSeen)}</td>

      {resolvedAt ? (
        <td>{formatNumericDate(resolvedAt)}</td>
      ) : (
        <td className="IssueItem__Controls Table__Cell--text-right">
          {!checkIsCritical(severity) && (
            <button
              className="Btn Btn--action"
              onClick={() => onArchive(issueId, !isArchived)}
            >
              <Icon glyph={isArchived ? "unarchive" : "archive"} />{" "}
              {isArchived ? "Wiederherstellen" : "Archivieren"}
            </button>
          )}
        </td>
      )}
    </tr>
  );
};

const getRealLink = ({
  linkData,
  siteId,
  pages,
  title,
}: {
  linkData: LinkData;
  siteId: string;
  pages: PageState;
  title: string | null;
}): IssueLink => {
  if (isModuleLink(linkData)) {
    const icon =
      (linkData.moduleType && getModuleIcon(linkData.moduleType)) || undefined;

    return {
      icon,
      title,
      href: getURL(
        siteId,
        "pages",
        linkData.pageId,
        linkData.languageId,
        "modules",
        linkData.moduleId,
      ),
    };
  }

  if (isPageLink(linkData) && pages.byId[linkData.pageId]) {
    const { isEnabled } = pages.byId[linkData.pageId];
    // TODO: remove hardcoded
    const type = "page";
    const mainPageId = pages.byParentId["null"][0];
    const isMainPage = linkData.pageId === mainPageId;
    return {
      href: getURL(siteId, "pages", linkData.pageId, linkData.languageId),
      icon: getPageIcon({ isEnabled, isMainPage, type }),
      title: title || "Seitenübersetzung anlegen",
    };
  }

  if (isExternalLink(linkData)) {
    return {
      href: linkData.url,
      title: title || "Link",
      icon: "link",
    };
  }

  return {};
};

const getIssueLink = ({
  type,
  link,
  siteId,
  pages,
  title,
}: {
  type: IssueType;
  link: LinkData | null;
  siteId: string;
  pages: PageState;
  title: string | null;
}): IssueLink | undefined => {
  const resolvedLink = link
    ? getRealLink({
        linkData: link,
        pages,
        siteId,
        title,
      })
    : {};

  switch (type) {
    case "missingGoogleTagManager":
    case "missingSiteOwnerData": {
      return {
        href: getURL(siteId, "settings"),
        icon: "settings",
        title: "Einstellungen",
      };
    }
    case "unreachableExternalLink":
    case "orphanedLink": {
      return {
        ...resolvedLink,
        href:
          link && isPageLink(link)
            ? getURL(resolvedLink.href, "settings")
            : resolvedLink.href,
      };
    }
    case "missingPageDescription": {
      return {
        ...resolvedLink,
        href: getURL(resolvedLink.href, "settings"),
      };
    }

    default:
      return resolvedLink;
  }
};

const mapStateToProps: MapStateToProps<StateProps, Props, StoreState> = (
  { pages, sites },
  { link, type, title },
): StateProps => {
  const site = getActiveSite(sites);

  const issueLink = getIssueLink({
    link,
    pages,
    siteId: site.id,
    type,
    title,
  });

  const isExternal = link ? isExternalLink(link) : false;
  return { issueLink, isExternal };
};

export default connect(mapStateToProps)(IssueItem);

import { AxiosResponse } from "axios";
import { Revision, ThunkAction } from "../types/index.js";
import { fetch, formatDateTime } from "../utils/utils.js";
import { showAlert } from "./Alerts.js";
import { resetStore } from "./Store.js";

export type Action =
  | {
      revisionId: string;
      isFavorite: boolean;
      type: "PATCH_REVISION_START";
    }
  | {
      type: "GET_REVISIONS_SUCCESS";
      revisions: Revision[];
    }
  | {
      type: "GET_REVISIONS_START";
    };

const patchRevisionStart = (
  revisionId: string,
  isFavorite: boolean,
): Action => ({
  type: "PATCH_REVISION_START",
  revisionId,
  isFavorite,
});

const getRevisionsSuccess = (revisions: Revision[]): Action => ({
  type: "GET_REVISIONS_SUCCESS",
  revisions,
});

const getRevisionsStart = (): Action => ({
  type: "GET_REVISIONS_START",
});

export const resetRevision = (
  siteId: string,
  revisionId: string,
): ThunkAction<Promise<void>> => {
  return (dispatch, getState) => {
    const { publishedAt } = getState().revisions.byId[revisionId];
    const timestamp = formatDateTime(publishedAt);
    return fetch({ dispatch })
      .post(`sites/${siteId}/revisions/${revisionId}/reset`)
      .then(() => {
        dispatch(resetStore());
        dispatch(
          showAlert(
            `Zurücksetzen auf den Stand vom ${timestamp} erfolgreich!`,
            "success",
          ),
        );
      })
      .catch((error) => {
        dispatch(
          showAlert(
            `Beim Zurücksetzen auf den Stand vom ${timestamp} ist ein Fehler aufgetreten!`,
          ),
        );
        throw error;
      });
  };
};

export const getRevisions = (siteId: string): ThunkAction<Promise<void>> => {
  return (dispatch) => {
    dispatch(getRevisionsStart());
    return fetch({ dispatch })
      .get(`sites/${siteId}/revisions`)
      .then((res) => {
        dispatch(getRevisionsSuccess(res.data));
      })
      .catch((error) => {
        dispatch(
          showAlert(
            "Beim Laden der Veröffentlichungen ist ein Fehler aufgetreten!",
          ),
        );
        throw error;
      });
  };
};

export const patchRevision = (
  siteId: string,
  revisionId: string,
  isFavorite: boolean,
): ThunkAction<Promise<AxiosResponse>> => {
  return (dispatch, getState) => {
    const { publishedAt } = getState().revisions.byId[revisionId];
    const timestamp = formatDateTime(publishedAt);
    dispatch(patchRevisionStart(revisionId, isFavorite));
    return fetch({ dispatch })
      .patch(`sites/${siteId}/revisions/${revisionId}`, { isFavorite })
      .catch((error) => {
        dispatch(
          showAlert(
            `Beim Favorisieren des Stands vom ${timestamp} ist ein Fehler aufgetreten!`,
          ),
        );
        // Undo the action by setting the inverse isFavorite status
        dispatch(patchRevisionStart(revisionId, !isFavorite));
        throw error;
      });
  };
};

import { FunctionComponent, useState } from "react";
import { SiteLanguage } from "../../server/types/index.js";
import i18n from "../i18n/index.js";
import { Language, SiteForm } from "../types/index.js";
import { gtmCheckRegex, isNotEmpty, moveArrayElement } from "../utils/utils.js";
import ControlItemsList from "./ControlItemsList.js";
import FormError from "./FormError.js";
import FormInfo from "./FormInfo.js";
import Icon from "./Icon.js";
import LanguageModal from "./LanguageModal.js";
import ModalDialog from "./ModalDialog.js";
import NewsletterDataExport from "./NewsletterDataExport.js";
import TextFormField from "./TextFormField.js";

interface Props {
  onSubmit: (form: SiteFormSubmit) => void;
  formValues: SiteForm;
  allLanguages: Language[];
  isAdminUser: boolean;
}

type SiteFormSubmit = Omit<SiteForm, "googleTagManagerId"> & Partial<SiteForm>;

const { languageNames } = i18n.de;

export const customItemTitle = (languageId: Language) =>
  languageNames[languageId];

const getListItemCaption = (
  languageId: Language,
  fallbackId: Language | null,
) =>
  customItemTitle(languageId) +
  (fallbackId ? ` (Fallback : ${customItemTitle(fallbackId)})` : "");

type OpenState = [isOpen: boolean, languageId: Language | undefined];

const SiteSettingsForm: FunctionComponent<Props> = ({
  formValues: adminFormValues,
  formValues: {
    googleMapsApiKey,
    legalRepresentative,
    vatId,
    languages: initialSiteLanguages,
  },
  allLanguages: languages,
  onSubmit,
  isAdminUser,
}) => {
  const { googleTagManagerId, ...nonAdminFormValues } = adminFormValues;
  const formValues = isAdminUser ? adminFormValues : nonAdminFormValues;

  const [[isLanguageModalOpen, languageToEdit], openLanguageModal] =
    useState<OpenState>([false, undefined]);

  const [[isDeleteModalOpen, languageToDelete], openDeleteModal] =
    useState<OpenState>([false, undefined]);

  const [siteLanguages, setSiteLanguages] = useState(initialSiteLanguages);

  return (
    <form className="Form">
      <section className="Sidebar__Section">
        <h3 className="Sidebar__Section__Title">Allgemein</h3>

        <div className="Form__Field">
          <div className="Form__Content Form__Content--column">
            <ControlItemsList
              items={siteLanguages.map(({ id, fallbackId }) => ({
                id,
                title: getListItemCaption(id, fallbackId),
              }))}
              onDragEnd={({ oldIndex, newIndex }) => {
                const updatedLanguages = moveArrayElement(
                  siteLanguages,
                  oldIndex,
                  newIndex,
                );

                onSubmit({
                  ...formValues,
                  languages: updatedLanguages,
                });
                setSiteLanguages(updatedLanguages);
              }}
              onChange={(itemId) => {
                const languageId = itemId as Language;
                openLanguageModal([true, languageId]);
              }}
              onRemove={(itemId) => {
                const languageId = itemId as Language;
                openDeleteModal([true, languageId]);
              }}
            />
            <button
              className="Btn Form__Content__Button"
              type="button"
              onClick={() => {
                openLanguageModal([true, undefined]);
              }}
            >
              <Icon glyph="add" /> Sprache hinzufügen
            </button>
          </div>
          <FormError
            error={
              !siteLanguages.length
                ? "Es muss mindestens eine Sprache ausgewählt sein."
                : undefined
            }
          />
          <div className="Form__Label">
            <label>Sprachen</label>
          </div>
        </div>

        <LanguageModal
          key={String(isLanguageModalOpen)}
          isOpen={isLanguageModalOpen}
          allLanguages={languages}
          siteLanguages={siteLanguages}
          initialLanguageId={languageToEdit}
          onClose={(status, newSiteLanguages) => {
            openLanguageModal([false, undefined]);
            if (status !== "confirmed") return;

            onSubmit({
              ...formValues,
              languages: newSiteLanguages,
            });
            setSiteLanguages(newSiteLanguages);
          }}
        />

        <ModalDialog
          isOpen={isDeleteModalOpen}
          type="confirm"
          title="Sprache löschen"
          onClose={(status) => {
            if (status === "confirmed") {
              const updatedLanguages = siteLanguages
                .filter((lang) => lang.id !== languageToDelete)
                .map<SiteLanguage>(({ id, fallbackId }) => ({
                  id,
                  // Remove the fallback id if it matches the removed language
                  fallbackId:
                    fallbackId === languageToDelete ? null : fallbackId,
                }));

              setSiteLanguages(updatedLanguages);

              // Only reflect to store if there’s at least one language
              if (updatedLanguages.length) {
                onSubmit({
                  ...formValues,
                  languages: updatedLanguages,
                });
              }
            }

            openDeleteModal([false, undefined]);
          }}
        >
          Achtung: Beim Löschen einer Sprache gehen alle Seiten und Module in
          dieser Sprache verloren!
        </ModalDialog>

        <TextFormField
          label="Google Tag Manager Container ID"
          disabled={!isAdminUser}
          onChange={(value) =>
            onSubmit({
              ...formValues,
              // This field can be null or a string
              googleTagManagerId: value || null,
            })
          }
          placeholder="Leer"
          value={googleTagManagerId ?? ""}
          validation={{
            errorMessage:
              "Die Google Tag Manager Container ID muss muss mit „GTM-“ beginnen.",
            validate: (value) => value === "" || gtmCheckRegex.test(value),
          }}
        >
          <ForAdminsOnlyMessage isAdminUser={isAdminUser} />
        </TextFormField>

        <TextFormField
          label="Google Maps API Key"
          onChange={(value) =>
            onSubmit({
              ...formValues,
              googleMapsApiKey: value || null,
            })
          }
          placeholder="Leer"
          value={googleMapsApiKey ?? ""}
        />
      </section>

      <section className="Sidebar__Section">
        <h3 className="Sidebar__Section__Title">
          Gesetzlich vorgeschriebene Daten
        </h3>

        <TextFormField
          label="MwSt.-Nr."
          onChange={(value) => onSubmit({ ...formValues, vatId: value })}
          placeholder="Leer"
          value={vatId || ""}
          validation={{
            errorMessage:
              "Die MwSt.-Nr. muss verpflichtend auf jeder Seite angezeigt werden.",
            validate: isNotEmpty,
          }}
        />

        <TextFormField
          label="Gesetzlicher Vertreter"
          onChange={(value) =>
            onSubmit({ ...formValues, legalRepresentative: value })
          }
          placeholder="Leer"
          value={legalRepresentative ?? ""}
          validation={{
            errorMessage:
              "Der gesetzliche Vertreter muss verpflichtend angegeben werden.",
            validate: isNotEmpty,
          }}
        />
      </section>

      <NewsletterDataExport />
    </form>
  );
};

const ForAdminsOnlyMessage: FunctionComponent<{ isAdminUser: boolean }> = ({
  isAdminUser,
}) =>
  isAdminUser ? null : (
    <FormInfo>Dieses Feld darf nur vom Support geändert werden.</FormInfo>
  );

export default SiteSettingsForm;

import { FunctionComponent } from "react";
import { connect } from "react-redux";
import { getActiveSite } from "../../selectors/sites.js";
import {
  BaseModuleProps,
  ColorScheme,
  Language,
  StoreState,
  TermsModuleSettings,
} from "../../types/index.js";
import { getActiveColorScheme, getTranslations } from "../../utils/utils.js";
import LegalNavLink from "../LegalNavLink.js";
import ModuleHeadings from "../ModuleHeadings.js";
import ModuleWithHeadings from "../ModuleWithHeadings.js";

interface Props extends BaseModuleProps<TermsModuleSettings> {}

interface StateProps {
  scheme: ColorScheme;
  i18n: typeof translations.en;
}

const translations = {
  de: {
    title: "Allgemeine Geschäftsbedingungen",
    content: (languageId: Language) => (
      <>
        <p>
          Mit dem Abschluss des Buchungsvorganges bestätigen Sie, dass Sie die
          unten angeführten Geschäftsbedingungen gelesen und verstanden haben,
          sowie dass Sie diese annehmen und ihre uneingeschränkte
          Rechtswirksamkeit anerkennen.
        </p>

        <h2>1. Preise</h2>
        <p>
          Alle auf der Website ausgewiesenen Preise schließen die Mehrwertsteuer
          ein. Bitte beachten Sie, dass am 01.01.2014 die Ortstaxe in Kraft
          getreten ist, welche in den ausgewiesenen Preisen NICHT inkludiert
          ist. Die Ortstaxe muss direkt im Betrieb vor Ort beglichen werden.
        </p>

        <h2>2. Datenschutz</h2>
        <p>
          Mit Abschluss der Buchung erteilen Sie die Zustimmung zur Verwendung
          Ihrer Daten gemäß den hier verlinkten Bedingungen. Entnehmen Sie der{" "}
          <LegalNavLink
            legalNavId="privacy"
            isPreview={false}
            languageId={languageId}
          >
            verlinkten Seite
          </LegalNavLink>{" "}
          auch Ihre Rechte bezüglich der Datenverarbeitung. Es ist Ihnen nicht
          gestattet, Inhalte oder Informationen, die auf unserer Webseite
          verfügbar sind, zu gewerblichen oder wettbewerblichen Zwecken zu
          vertreiben, mit Unterseiten von Webseiten zu nutzen, zu vervielfachen,
          zu extrahieren, neu zu veröffentlichen, hochzuladen und/oder zu
          reproduzieren.
        </p>

        <h2>3. Zahlungsmodalitäten</h2>
        <p>
          Die angewandten Zahlungsmodalitäten werden im Verlauf der Buchung
          angezeigt.
        </p>

        <h2>4. Stornierungen seitens des Gastes</h2>
        <p>
          Die angewandten Stornobedingungen werden im Verlauf der Buchung
          angezeigt.
        </p>

        <h2>5. Haftungsausschluss</h2>
        <p>
          Der Beherbergungsbetrieb haftet ausschließlich für unmittelbare
          Schäden, die dem Gast auf Grund der Nichterfüllung von Verpflichtungen
          des Beherbergungsbetriebs in Bezug auf ihre eigene Tätigkeit
          entstanden sind. In jedem Fall haftet der Beherbergungsbetrieb maximal
          bis zur Höhe des Gesamtpreises der Buchung, auf die sich der
          Haftungsanspruch bezieht.
        </p>

        <h2>6. Sonstiges</h2>
        <p>
          Diese allgemeinen Geschäftsbedingungen und die Bereitstellung unserer
          Dienstleistungen unterliegen dem italienischen Recht und sind gemäß
          diesem auszulegen. Für alle Streitigkeiten, die sich aus oder in
          Zusammenhang mit diesen allgemeinen Geschäftsbedingungen und unserer
          Dienstleistung ergeben, ist der ausschließliche Gerichtsstand Bozen
          (Italien). Ist oder wird eine der Bestimmungen dieses Vertrags
          unwirksam, bleiben alle anderen für die Vertragspartner geltenden
          Bestimmungen hiervon unberührt. Bei Problemen mit Online-Käufen können
          Sie versuchen, über dieses Portal zu einer außergerichtlichen Einigung
          zu gelangen:{" "}
          <a
            href="https://webgate.ec.europa.eu/odr/main/?event=main.home.show&lng=DE"
            target="_blank"
            rel="noopener"
          >
            webgate.ec.europa.eu
          </a>
          .<br />
          Sie können diesen Dienst nur nutzen, wenn Sie in der EU leben und der
          Händler in der EU niedergelassen ist.
        </p>
      </>
    ),
  },
  it: {
    title: "Condizioni Generali di Contratto",
    content: (languageId: Language) => (
      <>
        <p>
          Una volta conclusa la procedura di prenotazione, confermare di avere
          letto e compreso le Condizioni Generali di Contratto sotto elencate,
          di accettarle e di riconoscerne l'efficacia giuridica illimitata.
        </p>

        <h2>1. Prezzi</h2>
        <p>
          Tutti i prezzi indicati su questo sito sono comprensivi di I.V.A.. Si
          prega di notare che dal 01/01/2014 è in vigore la tassa di soggiorno,
          che NON è inclusa nei prezzi pubblicati. La tassa di soggiorno deve
          essere pagata direttamente presso la struttura ricettiva.
        </p>

        <h2>2. Tutela dei dati</h2>
        <p>
          Completando la prenotazione, acconsentirete al trattamento dei vostri
          dati personali in conformità con le condizioni consultabili al{" "}
          <LegalNavLink
            legalNavId="privacy"
            isPreview={false}
            languageId={languageId}
          >
            seguente link
          </LegalNavLink>
          . Sulla stessa pagina potrete inoltre informarvi sui vostri diritti
          relativi al trattamento dei dati. Il cliente non è autorizzato a
          divulgare contenuti o informazioni disponibili nel nostro sito web per
          scopi commerciali o di concorrenza, a utilizzare, duplicare,
          estrapolare, ripubblicare, caricare e/o riprodurre sottopagine di siti
          web.
        </p>

        <h2>3. Modalità di pagamento</h2>
        <p>
          Le modalità di pagamento previste saranno visualizzabili nel corso del
          processo di prenotazione.
        </p>

        <h2>4. Cancellazione da parte dell’ospite</h2>
        <p>
          Le condizioni di cancellazione previste saranno visualizzabili nel
          corso del processo di prenotazione.
        </p>

        <h2>5. Esclusione di responsabilità</h2>
        <p>
          Il servizio di ricettività turistica risponde esclusivamente dei danni
          diretti risultanti al cliente a causa del mancato adempimento da parte
          del servizio di ricettività turistica degli obblighi relativi alla
          propria attività. In ogni caso il servizio di ricettività turistica
          risponde al massimo per l'ammontare del prezzo totale della
          prenotazione, cui fa riferimento la responsabilità civile.
        </p>

        <h2>6. Altro</h2>
        <p>
          Le presenti Condizioni Generali di Contratto e la messa a disposizione
          delle nostre prestazioni sono soggetti al diritto italiano e devono
          essere interpretate conformemente ad esso. Per tutte le controversie
          risultanti o correlate con le presenti Condizioni Generali di
          Contratto è competente esclusivamente il Foro di Bolzano (Italia). Se
          una delle condizioni del presente contratto è o diventa nulla, ciò non
          inficia la validità di tutte le altre condizioni valide per le parti
          contraenti. Se ha riscontrato un problema con un acquisto online, può
          usare questo sito per trovare una soluzione extragiudiziale:{" "}
          <a
            href="https://webgate.ec.europa.eu/odr/main/?event=main.home.show&lng=IT"
            target="_blank"
            rel="noopener"
          >
            webgate.ec.europa.eu
          </a>
          .<br />
          Può solo essere usato se il consumatore risiede nell’UE e il
          commerciante ha sede nell’UE.
        </p>
      </>
    ),
  },
  en: {
    title: "General Conditions and Terms of Business",
    content: (languageId: Language) => (
      <>
        <p>
          By completing the booking process, you confirm that you have read,
          understood, and accepted the General Conditions and Terms of Business
          presented below and that you recognize their unrestricted legal
          validity.
        </p>

        <h2>1. Rates</h2>
        <p>
          All of the prices appearing on this website include VAT. Please note
          that as of 01/01/2014 a City Tax was introduced, which is NOT included
          in the published prices. The city tax is to be paid directly at the
          accommodation.
        </p>

        <h2>2. Data Protection</h2>
        <p>
          By completing the reservation, you agree to the processing of your
          personal data in accordance with the conditions set out in{" "}
          <LegalNavLink
            legalNavId="privacy"
            isPreview={false}
            languageId={languageId}
          >
            this link
          </LegalNavLink>
          . Please also refer to the linked page for your rights regarding data
          processing. You are prohibited from extracting, uploading,
          distributing, reproducing, or "framing" pages or content obtained from
          this website; in particular the use of this information for commercial
          or competitive purposes is expressly prohibited.
        </p>

        <h2>3. Payment</h2>
        <p>
          The terms of payment applied are indicated during the booking process.
        </p>

        <h2>4. Cancellations by the Guest</h2>
        <p>
          The cancellation terms applied are indicated during the booking
          process.
        </p>

        <h2>5. Exclusion of Liability</h2>
        <p>
          The lodgings establishment is liable exclusively for immediate damages
          which the guest incurs as the result of the non-fulfilment of the
          obligations of the lodgings establishment with regards to its own
          activities. In any case, the lodgings establishment is liable to a
          maximum amount corresponding to the total price of the booking to
          which the liability claim pertains.
        </p>

        <h2>6. Miscellaneous</h2>
        <p>
          These General Terms and Conditions of Business and the provision of
          our services are subject to Italian Law, and are to be interpreted
          accordingly. The court venue for all disputes arising from or in the
          context of these General Terms and Conditions of Business and our
          services shall be Bozen/Bolzano (Italy), only. If any provisions of
          this contract is or becomes ineffective, the effectiveness of the
          remaining provisions shall not be affected. If you’ve had a problem
          with something you’ve bought online, you can use this site to try to
          reach an out-of-court-settlement:{" "}
          <a
            href="https://webgate.ec.europa.eu/odr/main/?event=main.home.show&lng=EN"
            target="_blank"
            rel="noopener"
          >
            webgate.ec.europa.eu
          </a>
          .<br />
          You can only use it if you live in the EU and the trader is based in
          the EU.
        </p>
      </>
    ),
  },
};

const TermsModule: FunctionComponent<Props & StateProps> = ({
  scheme,
  i18n,
  translatedModule: {
    id,
    translation: { languageId },
  },
}) => (
  <ModuleWithHeadings
    title={i18n.title}
    subtitle={undefined}
    id={id}
    className="TermsModule TextModule"
    colors={{
      background: scheme.main.background,
      color: scheme.main.text,
    }}
  >
    <div className="TextModule__Wrapper Module__Wrapper Module__Wrapper--default-padding">
      <div className="TextModule__Content TextAlign--left">
        <ModuleHeadings
          textAlign={{ title: "left", subtitle: "left" }}
          isFirstOnPage={true}
          scheme={scheme}
          subtitle={undefined}
          title={i18n.title}
        />

        <section className="Module__BodyText TextAlign--left">
          {i18n.content(languageId)}
        </section>
      </div>
    </div>
  </ModuleWithHeadings>
);

const mapStateToProps = (
  { colorSchemes, sites }: StoreState,
  { translatedModule }: Props,
): StateProps => {
  const site = getActiveSite(sites);

  return {
    i18n: getTranslations(
      translatedModule.translation.languageId,
      translations,
    ),
    scheme: getActiveColorScheme(colorSchemes, site, translatedModule),
  };
};

export default connect(mapStateToProps)(TermsModule);

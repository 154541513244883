import { FunctionComponent } from "react";
import { connect, ConnectedProps, MapStateToProps } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { postPageTranslation } from "../actions/Pages.js";
import { getActiveSite } from "../selectors/sites.js";
import {
  Language,
  PageSettingsOnSave,
  PageTranslation,
  PageTranslationType,
  StoreState,
  ThunkDispatch,
} from "../types/index.js";
import {
  getDefaultPageTranslationSettings,
  getPageTranslationType,
  getTranslatedPage,
  getURL,
} from "../utils/utils.js";
import PageSettings from "./PageSettings.js";

type Props = RouteComponentProps<{
  siteId: string;
  pageId: string;
  languageId: Language;
}>;

interface StateProps {
  pageTranslationType: PageTranslationType;
  translation: PageTranslation;
  popUpModuleId: string | null;
  hasGlobalPopUpModule: boolean;
}

type DispatchProps = {
  onSave: PageSettingsOnSave;
};

type ReduxProps = ConnectedProps<typeof connector>;

const PageTranslationNew: FunctionComponent<Props & ReduxProps> = ({
  match: {
    params: { siteId, pageId, languageId },
  },
  pageTranslationType,
  translation,
  onSave,
  popUpModuleId,
  hasGlobalPopUpModule,
}) => {
  const title = "Neue Übersetzung";
  const closeLink = getURL(siteId, "pages", pageId, translation.languageId);
  const settings = getDefaultPageTranslationSettings(
    languageId,
    pageTranslationType,
  );

  return (
    <PageSettings
      title={title}
      languageId={languageId}
      onSave={onSave}
      settings={settings}
      translation={translation}
      closeLink={closeLink}
      pageTranslationType={pageTranslationType}
      pagesLimitReached={false}
      popUpModuleId={popUpModuleId}
      hasGlobalPopUpModule={hasGlobalPopUpModule}
    />
  );
};

const mapStateToProps: MapStateToProps<StateProps, Props, StoreState> = (
  { pages, sites },
  {
    match: {
      params: { pageId, languageId },
    },
  },
): StateProps => {
  const { translation, popUpModuleId } = getTranslatedPage(
    pages,
    pageId,
    languageId,
    true,
  );
  const pageTranslationType = getPageTranslationType(translation.link);

  return {
    pageTranslationType,
    translation,
    popUpModuleId,
    hasGlobalPopUpModule: getActiveSite(sites).popUpModuleId !== null,
  };
};

// There is a typings bug in bind action creator
// https://github.com/piotrwitek/react-redux-typescript-guide/issues/6
const mapDispatchToProps = (
  dispatch: ThunkDispatch,
  {
    history,
    match: {
      params: { siteId, pageId, languageId },
    },
  }: Props,
): DispatchProps => ({
  onSave: async ({ translation }) => {
    await dispatch(
      postPageTranslation(siteId, pageId, languageId, translation),
    );
    history.push(getURL(siteId, "pages", pageId, languageId));
  },
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default withRouter(connector(PageTranslationNew));

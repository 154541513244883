import ClassNames from "classnames";
import { Component, lazy, Suspense } from "react";
import { connect, ConnectedProps, MapStateToProps } from "react-redux";
import { matchPath, RouteComponentProps } from "react-router";
import { Route } from "react-router-dom";
import { SiteLanguage } from "../../server/types/index.js";
import { showAlert } from "../actions/Alerts.js";
import { pressEscapeKey } from "../actions/Keyboard.js";
import {
  copyModule,
  deleteModuleTranslation,
  getModules,
  moveModule,
  postModule,
  translateModule,
} from "../actions/Modules.js";
import { createModuleSections } from "../selectors/modules.js";
import { getActiveSite, getPrimaryDomain } from "../selectors/sites.js";
import {
  FontsByFamily,
  Language,
  Module,
  Modules,
  ModuleSection,
  ModuleWhatToRemove,
  OnModuleAdd,
  StoreState,
  TranslatedModule,
  TranslatedPage,
} from "../types/index.js";
import {
  addAndLoadFonts,
  deleteFonts,
  getFontFaces,
  getFontsByFamilyNames,
  mergeMainAndTitleFamilies,
} from "../utils/fonts.js";
import {
  checkIsHomePage,
  copiedModuleClipboard,
  getFallbackLanguage,
  getModuleSettingsCloseLink,
  getPageTranslationType,
  getShortId,
  getTopmostParentModuleId,
  getTranslatedModule,
  getTranslatedPage,
  getURL,
  isElementPartiallyInViewport,
  isExternalLink,
  resolveLink,
  scrollToEl,
} from "../utils/utils.js";
import IconLibrary from "./IconLibrary.js";
import LanguageNav from "./LanguageNav.js";
import MediaLibrary from "./MediaLibrary.js";
import ModuleList from "./ModuleList.js";
import ModuleSettingsLinkSelect from "./ModuleSettingsLinkSelect.js";
import PageDnD from "./PageDnD.js";
import PageLinkPreview from "./PageLinkPreview.js";
import PageNavigation from "./PageNavigation.js";
import PagePreview from "./PagePreview.js";
import PageTranslationNew from "./PageTranslationNew.js";
import PartnerLibrary from "./PartnerLibrary.js";
import PartnerLogoSelection from "./PartnerLogoSelection.js";

const ModuleSettings = lazy(() => import("./ModuleSettings.js"));

type Props = RouteComponentProps<{
  pageId: string;
  languageId: Language;
  moduleId?: string;
}>;

interface StateProps {
  fontFamily: string;
  titleFontFamily: string | undefined;
  activeModule: Module | undefined;
  moduleSections: ModuleSection[];
  page: TranslatedPage;
  languages: SiteLanguage[];
  pageUrl: string;
  isInactive: boolean;
  isAdminUser: boolean;
  modules: Modules;
  isHomePage: boolean;
  fontsByFamily: FontsByFamily | undefined;
}

type ReduxProps = ConnectedProps<typeof connector>;

type AllProps = Props & ReduxProps;

interface State {
  showDeleteConfirmModal: boolean;
  isModulePasteEnabled: boolean;
}

class Page extends Component<AllProps, State> {
  override readonly state: State = {
    showDeleteConfirmModal: false,
    isModulePasteEnabled: !!copiedModuleClipboard.get(this.props.page.siteId),
  };

  private moduleRefs: { [moduleId: string]: HTMLDivElement } = {};
  // Scroll to the moduleId from the URL when the page is loaded
  private scrollToModuleId: string | undefined =
    this.props.match.params.moduleId;
  private loadedFonts: FontFace[] = [];

  override componentDidMount() {
    document.addEventListener("keydown", this.handleKeyDown, false);
    document.addEventListener("copy", this.handleCopyToClipboard, {
      passive: true,
    });
    document.addEventListener("paste", this.handlePasteFromClipboard, {
      passive: true,
    });
    this.handlePageAndModuleLoad(true);
    this.loadFonts();
  }

  checkIsBodyActive = (target: EventTarget | null) =>
    document.activeElement === document.body || target === document.body;

  handleCopyToClipboard = (event: ClipboardEvent) => {
    // Abort if copy is triggered from an editable field
    if (!this.checkIsBodyActive(event.target)) return;

    const { activeModule } = this.props;
    // Only allow copying of top-level modules
    if (!activeModule || activeModule.parentId) return;

    this.handleModuleCopy(activeModule.id);
  };

  handlePasteFromClipboard = (event: ClipboardEvent) => {
    if (!this.checkIsBodyActive(event.target)) return;
    const { activeModule } = this.props;

    // Don’t paste when a submodule is active
    if (activeModule && activeModule.parentId) return;

    this.handleModulePaste(activeModule?.id);
  };

  scrollToModule = (moduleId: string) => {
    const el: HTMLDivElement | undefined = this.moduleRefs[moduleId];

    if (!el || (el && isElementPartiallyInViewport(el))) {
      return;
    }

    scrollToEl(el);
  };

  override componentDidUpdate(prevProps: AllProps) {
    const { match, fontFamily, titleFontFamily, fontsByFamily } = this.props;

    const pageSwitched = match.params.pageId !== prevProps.match.params.pageId;

    this.handlePageAndModuleLoad(pageSwitched);

    if (
      prevProps.fontFamily !== fontFamily ||
      prevProps.titleFontFamily !== titleFontFamily ||
      (!prevProps.fontsByFamily && fontsByFamily)
    ) {
      this.loadFonts();
    }

    const oldModuleId = prevProps.match.params.moduleId;
    const newModuleId = match.params.moduleId;

    // If the moduleId in the url has changed, e.g. if a link is clicked
    if (newModuleId && oldModuleId !== newModuleId) {
      this.scrollToModule(newModuleId);
    }
  }

  loadFonts = () => {
    const { fontFamily, titleFontFamily, fontsByFamily } = this.props;
    // Abort if fonts are not yet loaded
    if (!fontsByFamily) return;

    const fontFaces = getFontsByFamilyNames(
      fontsByFamily,
      mergeMainAndTitleFamilies(fontFamily, titleFontFamily),
    )
      .map(getFontFaces)
      .flat();

    this.unloadFonts();
    addAndLoadFonts(fontFaces);
    this.loadedFonts = fontFaces;
  };

  unloadFonts = () => {
    deleteFonts(this.loadedFonts);
    this.loadedFonts = [];
  };

  override componentWillUnmount() {
    this.unloadFonts();

    document.removeEventListener("keydown", this.handleKeyDown, false);
    document.removeEventListener("copy", this.handleCopyToClipboard);
    document.removeEventListener("paste", this.handlePasteFromClipboard);
  }

  isActiveElementEditable = (): boolean => {
    const { activeElement } = document;

    if (!activeElement) return false;

    return (
      activeElement.getAttribute("type") === "text" ||
      activeElement.getAttribute("role") === "textbox" ||
      activeElement.tagName === "TEXTAREA"
    );
  };

  handlePageAndModuleLoad = (forceLoad: boolean) => {
    const { page, getModules } = this.props;

    getModules({ siteId: page.siteId, pageId: page.id, forceLoad });
  };

  handleKeyDown = (e: KeyboardEvent) => {
    const {
      page,
      history,
      match: {
        params: { languageId, moduleId },
      },
      pressEscapeKey,
      activeModule,
    } = this.props;

    if (!moduleId) return;

    switch (e.key) {
      case "Escape": {
        // If linkSelect is open, close it
        pressEscapeKey();

        // If inside a submodule, navigate to the parent’s module settings,
        // otherwise go to the page tree
        history.push(
          getModuleSettingsCloseLink({
            languageId,
            pageId: page.id,
            moduleType: activeModule?.type,
            parentId: activeModule?.parentId,
            siteId: page.siteId,
          }),
        );
        break;
      }
      case "Delete": {
        !this.isActiveElementEditable() && this.handleModuleRemove();
        break;
      }
      case "ArrowUp": {
        e.ctrlKey &&
          !this.isActiveElementEditable() &&
          this.handleModuleMoveUp(moduleId);
        break;
      }
      // Down arrow
      case "ArrowDown": {
        e.ctrlKey &&
          !this.isActiveElementEditable() &&
          this.handleModuleMoveDown(moduleId);
        break;
      }
    }
  };

  handleModuleAdd: OnModuleAdd = ({
    index,
    moduleType,
    moduleId,
    usePageId,
  }) => {
    const {
      postModule,
      page,
      match: {
        params: { languageId },
      },
      moduleSections: [, pageModulesSection],
    } = this.props;

    const pageModules = pageModulesSection?.items ?? [];

    const nextId: string | undefined =
      index !== undefined && pageModules[index + 1]
        ? pageModules[index + 1]?.id
        : undefined;

    postModule({
      moduleId,
      siteId: page.siteId,
      pageId: usePageId ? page.id : null,
      moduleType,
      next: nextId,
      parentId: null,
      languageIds: [languageId],
    });

    this.scrollToModuleId = moduleId;
  };

  handleModuleRef = (el: HTMLDivElement | null, refModuleId: string) => {
    if (el) {
      this.moduleRefs[refModuleId] = el;
    }

    const doScroll =
      this.scrollToModuleId &&
      refModuleId ===
        getTopmostParentModuleId(this.scrollToModuleId, this.props.modules);

    if (doScroll) {
      this.scrollToModule(refModuleId);
      this.scrollToModuleId = undefined;
    }
  };

  handleModuleSelectAndTranslate = ({
    id: moduleId,
    languages,
  }: TranslatedModule) => {
    const {
      page,
      history,
      translateModule,
      match: {
        url: matchUrl,
        params: { languageId },
      },
    } = this.props;

    const translationExists = languages.includes(languageId);
    if (!translationExists) {
      const sourceLanguageId = languages.filter(
        (value) => value !== languageId,
      )[0];
      if (!sourceLanguageId) throw Error("Source language not found");

      !translationExists &&
        translateModule({
          siteId: page.siteId,
          moduleId,
          pageId: page.id,
          languageId,
          sourceLanguageId,
        });
    }

    const newUrl = getURL(
      page.siteId,
      "pages",
      page.id,
      languageId,
      "modules",
      moduleId,
    );

    newUrl !== matchUrl && history.push(newUrl);
  };

  handleModuleMoveUp = (moduleId: string) => {
    const { page, moveModule } = this.props;
    moveModule(page.siteId, page.id, moduleId, -1);
  };

  handleModuleMoveDown = (moduleId: string) => {
    const { page, moveModule } = this.props;
    moveModule(page.siteId, page.id, moduleId, 1);
  };

  handleModuleCopy = (moduleId: string) => {
    copiedModuleClipboard.set(this.props.page.siteId, moduleId);
    this.setState({ isModulePasteEnabled: true });
    this.props.showAlert(
      "Das Modul wurde in die Zwischenablage kopiert.",
      "message",
    );
  };

  handleModulePaste = async (targetModuleId?: string) => {
    const { page, copyModule } = this.props;
    const copiedId = copiedModuleClipboard.get(page.siteId);

    if (!copiedId) return;

    const newModuleShortId = getShortId();

    this.scrollToModuleId = newModuleShortId;

    await copyModule({
      siteId: page.siteId,
      targetPageId: page.id,
      moduleId: copiedId,
      targetModuleId: targetModuleId ?? null,
      newModuleShortId,
    });
  };

  handleModuleRemove = () => {
    this.setState({
      showDeleteConfirmModal: true,
    });
  };

  handleModuleRemoveConfirm = (
    moduleId: string,
    languageId: Language,
    toRemove: ModuleWhatToRemove,
  ) => {
    const { page, deleteModuleTranslation, history } = this.props;

    this.setState({
      showDeleteConfirmModal: false,
    });

    if (!moduleId || toRemove === "nothing") return;

    history.push(getURL(page.siteId, "pages", page.id, languageId, "modules"));
    deleteModuleTranslation({
      siteId: page.siteId,
      pageId: page.id,
      languageId,
      moduleId,
      deleteAllTranslations: toRemove === "allTranslations",
    });
  };

  checkHasLanguageAndModuleIdRouteParams = (
    props: RouteComponentProps,
  ): props is RouteComponentProps<{
    languageId: Language;
    moduleId: string;
  }> =>
    typeof (props.match.params as { moduleId?: string })?.moduleId ===
      "string" &&
    typeof (props.match.params as { languageId?: string })?.languageId ===
      "string";

  renderModuleSettings = (props: RouteComponentProps) => {
    if (!this.checkHasLanguageAndModuleIdRouteParams(props)) return null;
    const { languageId, moduleId } = props.match.params;

    const { page, activeModule } = this.props;
    const { showDeleteConfirmModal } = this.state;

    const translatedModule = activeModule
      ? getTranslatedModule(activeModule, languageId)
      : undefined;

    if (!translatedModule) return null;

    return (
      <Suspense>
        <ModuleSettings
          key={moduleId}
          siteId={page.siteId}
          pageId={page.id}
          translatedModule={translatedModule}
          showDeleteConfirmModal={showDeleteConfirmModal}
          onModuleCopy={this.handleModuleCopy}
          onModulePaste={this.handleModulePaste}
          onModuleMoveDown={this.handleModuleMoveDown}
          onModuleMoveUp={this.handleModuleMoveUp}
          onModuleRemove={this.handleModuleRemove}
          onModuleTranslate={this.handleModuleSelectAndTranslate}
          onModuleRemoveConfirm={this.handleModuleRemoveConfirm}
          isPasteEnabled={!!copiedModuleClipboard.get(page.siteId)}
        />
      </Suspense>
    );
  };

  override render() {
    const {
      fontFamily,
      page,
      languages,
      moduleSections,
      pageUrl,
      match: {
        params: { moduleId, languageId },
      },
      isInactive,
      isAdminUser,
      isHomePage,
    } = this.props;

    const [, pageModuleSection] = moduleSections;
    if (!pageModuleSection) return null;

    return (
      <PageDnD
        page={page}
        moduleSections={moduleSections}
        onModuleAdd={this.handleModuleAdd}
      >
        <div className="Page">
          <Route
            exact={true}
            path={getURL(":siteId", "pages", ":pageId", ":languageId")}
            component={isInactive ? PageTranslationNew : PageNavigation}
          />

          <Route
            exact={true}
            path={getURL(
              ":siteId",
              "pages",
              ":pageId",
              ":languageId",
              "modules",
            )}
            render={() => (
              <ModuleList
                isPage={
                  getPageTranslationType(page.translation.link) === "page"
                }
                moduleCount={pageModuleSection.items.length}
                onAdd={this.handleModuleAdd}
                isAdminUser={isAdminUser}
              />
            )}
          />

          <Route
            exact={true}
            path={getURL(
              ":siteId",
              "pages",
              ":pageId",
              ":languageId",
              "modules",
              ":moduleId",
            )}
            render={this.renderModuleSettings}
          />

          <Route
            exact={true}
            path={getURL(
              ":siteId",
              "pages",
              ":pageId",
              ":languageId",
              "modules",
              ":moduleId",
              "media",
            )}
            component={MediaLibrary}
          />

          <Route
            exact={true}
            path={getURL(
              ":siteId",
              "pages",
              ":pageId",
              ":languageId",
              "modules",
              ":moduleId",
              "icons",
            )}
            component={IconLibrary}
          />

          <Route
            exact={true}
            path={getURL(
              ":siteId",
              "pages",
              ":pageId",
              ":languageId",
              "modules",
              ":moduleId",
              "logos",
              ":logoCategoryId(0|1)",
            )}
            component={PartnerLibrary}
          />

          <Route
            exact={true}
            path={getURL(
              ":siteId",
              "pages",
              ":pageId",
              ":languageId",
              "modules",
              ":moduleId",
              "logos",
              ":logoCategoryId(0|1)",
              ":partnerId",
            )}
            component={PartnerLogoSelection}
          />

          <Route
            path={getURL(
              ":siteId",
              ":segment1?",
              ":pageId?",
              ":languageId?",
              ":segment2?",
              ":moduleId?",
            )}
            render={({
              match: {
                params: { segment2 = "" },
              },
            }) => {
              return (
                <div
                  className={ClassNames("Page__Main", "Site", {
                    "Site--is-home": isHomePage,
                    "Page__Main--inactive": isInactive,
                  })}
                >
                  <div
                    className={ClassNames("Page__HeaderBar", {
                      "Page__HeaderBar--page-disabled": !page.isEnabled,
                    })}
                  >
                    <div className="Page__Heading">
                      <h1 className="Page__Title">{page.translation.title}</h1>
                      <div className="Page__Url">
                        {page.isEnabled && (
                          <>
                            {page.translation.documentTitle
                              ? `${pageUrl} (${page.translation.documentTitle})`
                              : pageUrl}
                          </>
                        )}
                        {!page.isEnabled && "Seite deaktiviert"}
                      </div>
                    </div>
                    <LanguageNav
                      page={page}
                      languages={languages}
                      activeLanguage={languageId}
                      segment={segment2}
                    />
                  </div>

                  {page.translation.link ? (
                    <PageLinkPreview
                      link={page.translation.link}
                      languageId={languageId}
                    />
                  ) : (
                    <PagePreview
                      page={page}
                      languageId={languageId}
                      moduleSections={moduleSections}
                      fontFamily={fontFamily}
                      activeId={moduleId}
                      onModuleSelect={this.handleModuleSelectAndTranslate}
                      onModuleRef={this.handleModuleRef}
                      onPasteModule={
                        copiedModuleClipboard.get(page.siteId)
                          ? this.handleModulePaste
                          : undefined
                      }
                    />
                  )}
                </div>
              );
            }}
          />

          {moduleId && (
            <ModuleSettingsLinkSelect
              siteId={page.siteId}
              pageId={page.id}
              moduleId={moduleId}
            />
          )}
        </div>
      </PageDnD>
    );
  }
}

const mapStateToProps: MapStateToProps<StateProps, Props, StoreState> = (
  { sites, pages, modules, user, fonts },
  {
    match,
    match: {
      params: { languageId, moduleId, pageId },
      params,
    },
  },
): StateProps => {
  const site = getActiveSite(sites);
  const { fontFamily, titleFontFamily: titleFontFamily, languages } = site;
  const page = getTranslatedPage(pages, pageId, languageId, true);
  const { link } = page.translation;
  languageId = page.translation.languageId;

  // Create the resolved link for the preview.
  // Append the primary domain if it’s not an external link
  const pageUrl =
    (link && isExternalLink(link) ? "" : getPrimaryDomain(site)) +
    resolveLink({
      isPreview: false,
      languageId,
      pages,
      pageId: page.id,
      fallbackLanguageId: getFallbackLanguage(site, languageId),
    }).href;

  const isNewPage = !!matchPath(match.url, {
    path: getURL(":siteId", "pages", ":languageId", "new"),
    exact: true,
  });

  const activeModule = moduleId ? modules.byId[moduleId] : undefined;

  return {
    isInactive: languageId !== params.languageId || isNewPage,
    fontFamily,
    titleFontFamily: titleFontFamily || undefined,
    page,
    languages,
    activeModule,
    moduleSections: createModuleSections({
      modules,
      pageId: page.id,
      popUpModuleIds: modules.bySiteModuleType.PopUpModule ?? [],
      quickEnquiryModuleId: modules.bySiteModuleType.QuickEnquiryModule?.[0],
    }),
    pageUrl,
    isAdminUser: !!user.isAdmin,
    modules,
    isHomePage: checkIsHomePage(pages, page.id),
    fontsByFamily: fonts.allFamilies.length ? fonts.byFamily : undefined,
  };
};

const mapDispatchToProps = {
  getModules,
  postModule,
  translateModule,
  deleteModuleTranslation,
  copyModule,
  moveModule,
  pressEscapeKey,
  showAlert,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(Page);

import { FunctionComponent, useRef, useState } from "react";
import {
  Language,
  PageSettingsOnSave,
  PageTranslation,
  PageTranslationPostRequest,
  PageTranslationType,
} from "../types/index.js";
import { normalizeLink } from "../utils/utils.js";
import ModalDialog from "./ModalDialog.js";
import PageSettingsForm from "./PageSettingsForm.js";
import Sidebar from "./Sidebar.js";

interface Props {
  settings: PageTranslationPostRequest;
  translation?: PageTranslation;
  languageId: Language;
  title: string;
  pageTranslationType: PageTranslationType;
  closeLink: string;
  onSave: PageSettingsOnSave;
  pagesLimitReached: boolean;
  popUpModuleId: string | null;
  hasGlobalPopUpModule: boolean;
}

const normalizeExternalPageLinkUrl = (
  form: PageTranslationPostRequest,
): PageTranslationPostRequest => {
  if (!form.link || !form.link.url) return form;
  return {
    ...form,
    link: {
      ...form.link,
      url: normalizeLink(form.link.url),
    },
  };
};

const PageSettings: FunctionComponent<Props> = ({
  languageId,
  translation,
  title,
  pageTranslationType,
  closeLink,
  settings,
  onSave,
  pagesLimitReached,
  popUpModuleId,
  hasGlobalPopUpModule,
}) => {
  const [isModalOpen, showModal] = useState(false);
  const submitValues = useRef<PageTranslationPostRequest>();
  const langLabel = languageId.toUpperCase();
  const showModalOnSave = pagesLimitReached && pageTranslationType === "page";

  const handleSubmit: PageSettingsOnSave = ({ translation, popUpModuleId }) => {
    if (showModalOnSave && !isModalOpen) {
      submitValues.current = translation;
      showModal(true);
      return;
    }

    const normalizedForm = normalizeExternalPageLinkUrl(translation);
    onSave({
      translation: normalizedForm,
      isEnabled: !showModalOnSave,
      popUpModuleId,
    });
  };

  return (
    <Sidebar
      className="PageSettings"
      icon={pageTranslationType}
      heading={`${title} (${langLabel})`}
      closeLink={closeLink}
    >
      <PageSettingsForm
        initialFormValues={settings}
        languageId={languageId}
        pageTranslationType={pageTranslationType}
        translation={translation}
        onSubmit={handleSubmit}
        initialPopUpModuleId={popUpModuleId}
        hasGlobalPopUpModule={hasGlobalPopUpModule}
      />
      <ModalDialog
        isOpen={isModalOpen}
        type="message"
        title="Seite anlegen"
        actions={[
          {
            title: "OK",
            onClick: () => {
              submitValues.current &&
                handleSubmit({
                  translation: submitValues.current,
                  popUpModuleId,
                });
              showModal(false);
            },
          },
        ]}
        onClose={() => showModal(false)}
      >
        Das Seitenlimit für aktivierte Seiten wurde erreicht. Diese Seite wird
        als deaktivierte Seite angelegt.
      </ModalDialog>
    </Sidebar>
  );
};

export default PageSettings;

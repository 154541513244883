import {
  DndContext,
  PointerSensor,
  pointerWithin,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { ChangeEvent, FunctionComponent, useEffect, useState } from "react";
import { ConnectedProps, MapStateToProps, connect } from "react-redux";
import { RouteComponentProps, useHistory, withRouter } from "react-router";
import { SiteLanguage } from "../../server/types/index.js";
import {
  deletePageTranslation,
  dragPage,
  patchPage,
} from "../actions/Pages.js";
import { getActiveSite } from "../selectors/sites.js";
import {
  Language,
  ModalStatus,
  Page,
  PageLimits,
  PageTranslationType,
  PagesLimit,
  StoreState,
} from "../types/index.js";
import {
  getPageLimits,
  getTranslationFallbackLanguage,
  getURL,
} from "../utils/utils.js";
import FormInfo from "./FormInfo.js";
import ModalDialog from "./ModalDialog.js";
import PageTreeItem from "./PageTreeItem.js";
import { Props as DropTargetProps } from "./PageTreeItemDropTarget.js";
import PageTreeToolbar from "./PageTreeToolbar.js";
import Spinner from "./Spinner.js";

type RouteProps = RouteComponentProps<{
  siteId: string;
  pageId?: string;
  languageId?: Language;
}>;

type Props = {
  onPageSelect?: (pageId: string, languageId: Language) => void;
  showActionButtons?: boolean;
  languageFallback?: boolean;
  showLanguageSelection?: boolean;
  activePageId?: string;
  languageId?: Language;
  allowFolderSelection?: boolean;
  showPopUpIcon?: boolean;
} & RouteProps;

interface StateProps {
  languageId: Language;
  pagesAreLoaded: boolean;
  parentPages: string[];
  activeId?: string;
  page: Page | undefined;
  pageLimits: PageLimits;
  pagesLimit: PagesLimit;
  languages: SiteLanguage[];
  mainPageId: string;
  systemPages: string[];
}

type ReduxProps = ConnectedProps<typeof connector>;

const PageTree: FunctionComponent<Props & ReduxProps> = ({
  parentPages,
  pagesAreLoaded,
  showActionButtons = true,
  showLanguageSelection = true,
  activeId,
  page,
  languages,
  pageLimits,
  pagesLimit,
  onPageSelect,
  languageFallback = true,
  mainPageId,
  systemPages,
  allowFolderSelection = true,
  showPopUpIcon = false,
  languageId: languageIdProp,
  dragPage,
  patchPage,
  deletePageTranslation,
  match: {
    params: { siteId, languageId: languageIdFromUrl },
  },
}) => {
  const history = useHistory();
  const [languageId, setLanguageId] = useState<Language>(languageIdProp);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        // Set a distance threshold to allow clicking on pages.
        distance: 8,
      },
    }),
  );

  useEffect(() => {
    languageIdFromUrl && setLanguageId(languageIdFromUrl);
  }, [languageIdFromUrl]);

  const handlePageSelect = (pageId: string, languageId: Language) =>
    history.push(getURL(page!.siteId, "pages", pageId, languageId));

  const handlePageAdd = (pageType: PageTranslationType) =>
    history.push({
      pathname: getURL(page!.siteId, "pages", page!.id, languageId, "new"),
      search: `?type=${pageType}`,
    });

  const handlePageEnabledStatus = (isEnabled: boolean) =>
    patchPage(page!.siteId, page!.id, {
      isEnabled,
    });

  const handlePageDelete = () => setIsModalOpen(true);

  const handleLanguageSelection = (e: ChangeEvent<HTMLSelectElement>) =>
    setLanguageId(e.currentTarget.value as Language);

  const handlePageDeleteConfirm = (status: ModalStatus) => {
    setIsModalOpen(false);
    if (!page || status === "refuted") return;

    history.replace(getURL(page.siteId, "pages"));
    deletePageTranslation(page.id, languageId);
  };

  const handlePageTranslationDeleteConfirm = (status: ModalStatus) => {
    setIsModalOpen(false);

    if (!page || status === "refuted") return;

    history.replace(
      getURL(
        page.siteId,
        "pages",
        page.id,
        getTranslationFallbackLanguage(page, languageId),
      ),
    );
    deletePageTranslation(page.id, languageId);
  };

  const onSelect = onPageSelect ? onPageSelect : handlePageSelect;
  const translationCount = (page && Object.keys(page.translations).length) || 0;

  const pageTreeList = (pagesList: string[], enableDrag: boolean) => (
    <DndContext
      sensors={sensors}
      collisionDetection={pointerWithin}
      onDragEnd={({ active, over }) => {
        if (!over) return;
        const drop = over.data.current as DropTargetProps | undefined;
        if (!drop) return;
        dragPage({
          siteId,
          sourcePageId: String(active.id),
          targetPageId: drop.pageId,
          insertBefore: drop.isFirst,
          isNewSubtree: drop.isNewSubtree,
        });
      }}
    >
      <ul className="PageTree">
        {pagesList.map((id, index) => (
          <PageTreeItem
            key={id}
            index={index}
            pageId={id}
            hasCustomOnPageSelect={!!onPageSelect}
            activeId={activeId}
            languageFallback={languageFallback}
            matchedLanguageId={languageIdProp}
            languageId={languageId}
            onSelect={onSelect}
            enableDrag={enableDrag}
            allowFolderSelection={allowFolderSelection}
            showPopUpIcon={showPopUpIcon}
          />
        ))}
      </ul>
    </DndContext>
  );

  return (
    <div className="PageTree__Container Form">
      {showActionButtons && (
        <PageTreeToolbar
          page={page}
          pageLimits={pageLimits}
          languageId={languageId}
          onPageAdd={handlePageAdd}
          onPageEnabledStatusChange={handlePageEnabledStatus}
          onPageDelete={handlePageDelete}
          mainPageId={mainPageId}
        />
      )}

      {showLanguageSelection && (
        <select
          className="PageTree__Toolbar PageTree__LanguageSelection"
          onChange={handleLanguageSelection}
          value={languageId}
        >
          {languages.map(({ id: lang }) => (
            <option key={lang} value={lang}>
              {lang.toUpperCase()}
            </option>
          ))}
        </select>
      )}

      <div className="PageTree__Wrapper">
        {!pagesAreLoaded && (
          <ul className="PageTree">
            <li className="PageTree--is-loading">
              <Spinner />
            </li>
          </ul>
        )}
        {pageTreeList(parentPages, true)}
        <hr className="PageTree__Separator" />
        {pageTreeList(systemPages, false)}
      </div>

      {translationCount === 1 && (
        <ModalDialog
          title="Seite löschen"
          type="confirm"
          onClose={handlePageDeleteConfirm}
          isOpen={isModalOpen}
        >
          Wollen Sie diese Seite wirklich löschen? Achtung, eventuelle
          Unterseiten sind auch von der Löschung betroffen!
        </ModalDialog>
      )}

      {translationCount > 1 && (
        <ModalDialog
          title="Übersetzung löschen"
          type="confirm"
          onClose={handlePageTranslationDeleteConfirm}
          isOpen={isModalOpen}
        >
          Wollen Sie diese Übersetzung wirklich löschen?
        </ModalDialog>
      )}

      {showActionButtons && (
        <div className="PageTree__Notifications">
          {!pageLimits.enabled.limitReached && (
            <FormInfo>
              {pageLimits.enabled.count} von {pagesLimit} Seiten angelegt.
            </FormInfo>
          )}
          {pageLimits.enabled.limitReached && !pageLimits.all.limitReached && (
            <FormInfo>
              Die maximale Anzahl von {pagesLimit}{" "}
              {pagesLimit > 1 ? "Seiten" : "Seite"} wurde erreicht. Neue Seiten
              werden als deaktiviert angelegt.
            </FormInfo>
          )}
          {pageLimits.all.limitReached && (
            <FormInfo>
              Die max. Anzahl von {pagesLimit}{" "}
              {pagesLimit > 1 ? "Seiten" : "Seite"} wurde erreicht. Für das
              Hinzufügen weiterer Seiten ist die Umstellung auf eine größere
              Version erforderlich. Wenden Sie sich hierfür bitte an den
              Support.
            </FormInfo>
          )}
        </div>
      )}
    </div>
  );
};

const mapStateToProps: MapStateToProps<StateProps, Props, StoreState> = (
  { pages, loadStates, sites },
  {
    languageId,
    match: {
      params,
      params: { pageId },
    },
    activePageId,
  },
): StateProps => {
  const { languages, pagesLimit } = getActiveSite(sites);
  const finalLanguageId = languageId || params.languageId || languages[0].id;
  const activeId = activePageId || pageId;
  const page = pageId ? pages.byId[pageId] : undefined;
  const pageLimits = getPageLimits(pages, pagesLimit);

  return {
    pagesAreLoaded: loadStates.pages === "loaded",
    parentPages: pages.byParentId["null"],
    page,
    activeId,
    languageId: finalLanguageId,
    languages,
    pageLimits,
    pagesLimit,
    mainPageId: pages.byParentId["null"][0],
    systemPages: pages.systemPages,
  };
};

const mapDispatchToProps = {
  dragPage,
  patchPage,
  deletePageTranslation,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

const PageTreeWithRedux = connector(PageTree);

export default withRouter(PageTreeWithRedux);

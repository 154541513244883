import { IssuesChecker } from "../../server/types/index.js";
import {
  GetIssuesQueryParams,
  IssueItemType,
  ThunkAction,
} from "../types/index.js";
import { IssueNotFoundError, fetch } from "../utils/utils.js";
import { showAlert } from "./Alerts.js";

export type Action =
  | { type: "GET_ISSUES_START"; keepExisting: boolean }
  | { type: "GET_ISSUES_SUCCESS"; response: IssueItemType[] }
  | { type: "GET_ISSUES_ERROR" }
  | { type: "GET_ISSUES_CHECK_START" }
  | {
      type: "GET_ISSUES_CHECK_SUCCESS";
      response: IssuesChecker;
    }
  | { type: "GET_ISSUES_CHECK_ERROR" }
  | { type: "POST_ISSUES_CHECK_START" }
  | {
      type: "POST_ISSUES_CHECK_SUCCESS";
      response: IssuesChecker;
    }
  | { type: "POST_ISSUES_CHECK_ERROR" }
  | { type: "PATCH_ISSUE_START"; issueId: string; archive: boolean }
  | { type: "PATCH_ISSUE_SUCCESS" }
  | { type: "PATCH_ISSUE_ERROR" };

const getIssuesStart = (keepExisting: boolean): Action => ({
  type: "GET_ISSUES_START",
  keepExisting,
});

const getIssuesError = (): Action => ({
  type: "GET_ISSUES_ERROR",
});

const getIssuesSuccess = (response: IssueItemType[]): Action => ({
  type: "GET_ISSUES_SUCCESS",
  response,
});

export const getIssues =
  (
    siteId: string,
    params: GetIssuesQueryParams,
    keepExisting: boolean,
  ): ThunkAction<Promise<void>> =>
  async (dispatch) => {
    dispatch(getIssuesStart(keepExisting));
    try {
      const { data } = await fetch({ dispatch }).get<IssueItemType[]>(
        `sites/${siteId}/seo/issues`,
        {
          params,
        },
      );

      dispatch(getIssuesSuccess(data));
    } catch (error) {
      dispatch(getIssuesError());
      dispatch(
        showAlert(
          "Das Laden der Liste mit den Fehlern / Warnungen ist fehlgeschlagen. Versuchen Sie es später erneut!",
        ),
      );
      throw error;
    }
  };

const getIssuesCheckStart = (): Action => ({
  type: "GET_ISSUES_CHECK_START",
});

const getIssuesCheckError = (): Action => ({
  type: "GET_ISSUES_CHECK_ERROR",
});

const getIssuesCheckSuccess = (response: IssuesChecker): Action => ({
  type: "GET_ISSUES_CHECK_SUCCESS",
  response,
});

export const getIssuesCheck =
  ({
    siteId,
    forceUpdate,
    showLastResult,
  }: {
    siteId: string;
    forceUpdate: boolean;
    showLastResult: boolean;
  }): ThunkAction<Promise<void>> =>
  async (dispatch, getState) => {
    if (getState().loadStates.issues.checker !== "unloaded" && !forceUpdate) {
      return;
    }
    dispatch(getIssuesCheckStart());
    try {
      const { data } = await fetch({ dispatch }).get<IssuesChecker>(
        `sites/${siteId}/seo/issues/check`,
        { params: { showLastResult } },
      );
      dispatch(getIssuesCheckSuccess(data));
    } catch (error) {
      dispatch(getIssuesCheckError());
      dispatch(
        showAlert(
          "Das Laden der Daten zur Problembehebung ist fehlgeschlagen. Versuchen Sie es später erneut!",
        ),
      );
      throw error;
    }
  };

const postIssueCheckStart = (): Action => ({
  type: "POST_ISSUES_CHECK_START",
});

const postIssueCheckSuccess = (response: IssuesChecker): Action => ({
  type: "POST_ISSUES_CHECK_SUCCESS",
  response,
});

const postIssuesCheckError = (): Action => ({
  type: "POST_ISSUES_CHECK_ERROR",
});

export const postIssuesCheck =
  (siteId: string): ThunkAction<Promise<void>> =>
  async (dispatch) => {
    dispatch(postIssueCheckStart());
    try {
      const { data } = await fetch({ dispatch }).post<IssuesChecker>(
        `sites/${siteId}/seo/issues/check`,
      );
      dispatch(postIssueCheckSuccess(data));
    } catch (error) {
      dispatch(postIssuesCheckError());
      dispatch(
        showAlert(
          "Das Starten der Überprüfung ist fehlgeschlagen. Versuchen Sie es später erneut!",
        ),
      );
      throw error;
    }
  };

const patchIssueStart = (issueId: string, isArchived: boolean): Action => ({
  type: "PATCH_ISSUE_START",
  issueId,
  archive: isArchived,
});

const patchIssueSuccess = (): Action => ({
  type: "PATCH_ISSUE_SUCCESS",
});

const patchIssueError = (): Action => ({
  type: "PATCH_ISSUE_ERROR",
});

export const patchIssue =
  (
    siteId: string,
    issueId: string,
    archive: boolean,
  ): ThunkAction<Promise<void>> =>
  async (dispatch, getState) => {
    const currentIssue = getState().issues.issues.byId[issueId];
    if (!currentIssue) throw new IssueNotFoundError(issueId);
    const previousIsArchived = currentIssue.isArchived;
    dispatch(patchIssueStart(issueId, archive));
    try {
      await fetch({ dispatch }).patch<void>(
        `sites/${siteId}/seo/issues/${issueId}`,
        {
          isArchived: archive,
        },
      );
      dispatch(patchIssueSuccess());
    } catch (error) {
      dispatch(patchIssueError());
      // Revert to previous isArchived state
      dispatch(patchIssueStart(issueId, previousIsArchived));
      dispatch(
        showAlert(
          "Das Speichern des Elements ist fehlgeschlagen. Versuchen Sie es später erneut!",
        ),
      );
      throw error;
    }
  };

import { ChangeEventHandler, FunctionComponent, useState } from "react";
import { SiteLanguage } from "../../server/types/index.js";
import { Language, ModalStatus } from "../types/index.js";
import Checkbox from "./Checkbox.js";
import FormInfo from "./FormInfo.js";
import ModalDialog from "./ModalDialog.js";
import { customItemTitle } from "./SiteSettingsForm.js";

interface Props {
  key: string;
  isOpen: boolean;
  allLanguages: Language[];
  siteLanguages: SiteLanguage[];
  initialLanguageId: Language | undefined;
  onClose: (status: ModalStatus, siteLanguages: SiteLanguage[]) => void;
}

interface State {
  languageId: Language | undefined;
  fallbackLanguageId: Language | undefined;
  selectableLanguages: SelectableLanguages;
}

interface SelectableLanguages {
  enabled: Language[];
  disabled: Language[];
}

const getFallbackLanguage = (
  siteLanguages: SiteLanguage[],
  languageId: Language,
): Language | undefined => {
  const siteLanguage = siteLanguages.find(({ id }) => id === languageId);
  return (siteLanguage && siteLanguage.fallbackId) || undefined;
};

const createInitialState = (props: Props): State => {
  const { initialLanguageId, siteLanguages } = props;
  const selectableLanguages = getSelectableLanguages(props);

  return {
    languageId: initialLanguageId || selectableLanguages.enabled[0],
    fallbackLanguageId: initialLanguageId
      ? getFallbackLanguage(siteLanguages, initialLanguageId)
      : undefined,
    selectableLanguages,
  };
};

const getSelectableLanguages = ({
  siteLanguages,
  allLanguages,
}: Props): SelectableLanguages => {
  const isEnabled = (lang: Language) => {
    return siteLanguages.map(({ id }) => id).indexOf(lang) === -1;
  };

  return {
    enabled: allLanguages.filter(isEnabled),
    disabled: allLanguages.filter((lang) => !isEnabled(lang)),
  };
};

const LanguageModal: FunctionComponent<Props> = (props) => {
  const { siteLanguages, allLanguages, initialLanguageId, onClose, isOpen } =
    props;

  const [state, setState] = useState(createInitialState(props));

  const getSelectableLanguages = (): {
    enabled: Language[];
    disabled: Language[];
  } => {
    const checkIsEnabled = (lang: Language) =>
      siteLanguages.map(({ id }) => id).indexOf(lang) === -1;

    return {
      enabled: allLanguages.filter(checkIsEnabled),
      disabled: allLanguages.filter((lang) => !checkIsEnabled(lang)),
    };
  };

  const getSelectableFallbackLanguages = (): Language[] =>
    siteLanguages
      .map(({ id }) => id)
      .filter((lang) => lang !== initialLanguageId);

  const handleClose = (status: ModalStatus) => {
    const { languageId, fallbackLanguageId } = state;

    if (!languageId) {
      return onClose(status, siteLanguages);
    }

    // If it’s a new language
    if (!initialLanguageId) {
      const siteLanguage: SiteLanguage = {
        id: languageId,
        fallbackId: fallbackLanguageId || null,
      };

      const newSiteLanguages: SiteLanguage[] = [...siteLanguages, siteLanguage];

      return onClose(status, newSiteLanguages);
    }

    const newSiteLanguages: SiteLanguage[] = siteLanguages.map(
      ({ id, fallbackId }) => {
        const newFallbackLang =
          id === initialLanguageId ? fallbackLanguageId || null : fallbackId;

        return {
          id,
          fallbackId: newFallbackLang,
        };
      },
    );

    return onClose(status, newSiteLanguages);
  };

  const changeLanguage: ChangeEventHandler<HTMLSelectElement> = (e) =>
    setState({
      ...state,
      languageId: e.target.value as Language,
    });

  const toggleFallbackLanguage = () =>
    setState({
      ...state,
      fallbackLanguageId: !state.fallbackLanguageId
        ? getSelectableFallbackLanguages()[0]
        : undefined,
    });

  const changeFallbackLanguage: ChangeEventHandler<HTMLSelectElement> = (e) =>
    setState({
      ...state,
      fallbackLanguageId: e.target.value as Language,
    });

  const selectableLanguages = getSelectableLanguages();
  const selectableFallbackLanguages = getSelectableFallbackLanguages();
  const hasOneSiteLanguage = siteLanguages.length === 1;

  const { languageId, fallbackLanguageId } = state;

  return (
    <ModalDialog
      title={initialLanguageId ? "Sprache bearbeiten" : "Sprache hinzufügen"}
      isOpen={isOpen}
      type="confirm"
      onClose={handleClose}
    >
      <form className="Form">
        <div className="Form__Field">
          <label htmlFor="language-form-language">
            {initialLanguageId ? (
              customItemTitle(initialLanguageId)
            ) : (
              <select
                value={languageId}
                id="language-form-language"
                onChange={changeLanguage}
              >
                {selectableLanguages.enabled.map((lang) => {
                  return (
                    <option key={lang} value={lang}>
                      {customItemTitle(lang)}
                    </option>
                  );
                })}
                {selectableLanguages.disabled.map((lang) => {
                  return (
                    <option disabled key={lang} value={lang}>
                      {customItemTitle(lang)}
                    </option>
                  );
                })}
              </select>
            )}
          </label>
        </div>

        {(!hasOneSiteLanguage || initialLanguageId === undefined) && (
          <Checkbox
            checkedStatus={!!fallbackLanguageId}
            htmlId="language-form-fallback-enabled"
            text="Fallback für diese Sprache aktivieren"
            onChange={toggleFallbackLanguage}
          />
        )}

        {fallbackLanguageId && (
          <div className="Form__Field">
            <div className="Form__Content">
              <select
                value={fallbackLanguageId}
                id="language-form-fallback-language"
                onChange={changeFallbackLanguage}
              >
                {selectableFallbackLanguages.map((lang) => {
                  return (
                    <option key={lang} value={lang}>
                      {customItemTitle(lang)}
                    </option>
                  );
                })}
              </select>
            </div>
            <FormInfo>
              Die „Fallback“-Sprache kommt dann zum Einsatz, wenn der Inhalt der
              Seite in bestimmten Sprachen nicht bzw. nicht vollständig
              übersetzt wurde.
            </FormInfo>
            <div className="Form__Label">
              <label htmlFor="language-form-fallback-language">
                Fallback-Sprache
              </label>
            </div>
          </div>
        )}
      </form>
    </ModalDialog>
  );
};

export default LanguageModal;

import classNames from "classnames";
import { FunctionComponent } from "react";
import { connect, ConnectedProps, MapStateToProps } from "react-redux";
import { StoreState, TranslatedPage, type SeoUrl } from "../types/index.js";
import {
  formatNumericDate,
  getTranslatedPage,
  urlToBase64,
} from "../utils/utils.js";
import FormError from "./FormError.js";
import Icon from "./Icon.js";

interface Props {
  activeUrlId?: string;
  url: SeoUrl;
  onRedirect: (urlId: string) => void;
  onArchive: (urlId: string, isArchived: boolean) => void;
}

type Icon = "redirect" | "archive" | "warning";

interface StateProps {
  page: TranslatedPage | undefined;
  icon: Icon;
  pageError: string | undefined;
}

type ReduxProps = ConnectedProps<typeof connector>;

const SeoUrl: FunctionComponent<Props & ReduxProps> = ({
  url: { url, isArchived, firstDetected, lastCrawled },
  page,
  icon,
  onRedirect,
  onArchive,
  activeUrlId,
  pageError,
}) => {
  const urlId = urlToBase64(url);

  const isActive = urlId === activeUrlId;

  const className = classNames("IssueItem", {
    "IssueItem--active": isActive,
  });

  const statusClassName = classNames("IssueItem__Status", {
    [`IssueItem__Status--${icon}`]: true,
  });

  return (
    <tr key={url} className={className}>
      <td className={statusClassName}>
        <Icon glyph={icon} />
      </td>
      <td className="IssueItem__Url">{url}</td>
      <td>
        <Icon glyph="arrow-right" />
      </td>
      <td>
        <button className="Btn" onClick={() => onRedirect(urlId)}>
          <Icon glyph="page" /> {page ? page.translation.title : "Auswählen"}
        </button>
        <FormError error={pageError} />
      </td>
      <td>{formatNumericDate(firstDetected)}</td>
      <td>{formatNumericDate(lastCrawled)}</td>
      <td className="IssueItem__Controls Table__Cell--text-right">
        {isArchived && (
          <button
            className="Btn Btn--action"
            onClick={() => onArchive(urlId, false)}
          >
            <Icon glyph="unarchive" /> Wiederherstellen
          </button>
        )}
        {!isArchived && (
          <button
            className="Btn Btn--action"
            onClick={() => onArchive(urlId, true)}
          >
            <Icon glyph="archive" /> Archivieren
          </button>
        )}
      </td>
    </tr>
  );
};

const mapStateToProps: MapStateToProps<StateProps, Props, StoreState> = (
  { pages },
  { url: { isArchived, pageTranslationId } },
): StateProps => {
  let page: TranslatedPage | undefined;
  let pageError: string | undefined;

  if (Array.isArray(pageTranslationId)) {
    const [pageId, languageId] = pageTranslationId;

    try {
      page = getTranslatedPage(pages, pageId, languageId);
    } catch (error) {
      pageError = `Ausgewählte Seite ${pageId} (${languageId.toUpperCase()}) konnte nicht gefunden werden.`;
    }
  }

  const icon = isArchived ? "archive" : page ? "redirect" : "warning";

  return {
    page,
    icon,
    pageError,
  };
};

const connector = connect(mapStateToProps);

export default connector(SeoUrl);

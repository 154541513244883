import { LinkData } from "../types/index.js";

export type Action =
  | {
      type: "SET_LINK_SELECT";
      id: string;
      linkData: LinkData;
      confirm: boolean;
    }
  | {
      type: "RESET_LINK_SELECT";
    };

export const setLinkSelect = (
  id: string,
  linkData: LinkData,
  confirm: boolean,
): Action => ({
  type: "SET_LINK_SELECT",
  id,
  linkData,
  confirm,
});

export const resetLinkSelect = (): Action => ({
  type: "RESET_LINK_SELECT",
});

import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { FunctionComponent } from "react";
import {
  ContainerQueries,
  DragDropType,
  DragDropTypes,
  Language,
  Module,
  ModuleSettingsTypes,
  ModuleSortInsertPosition,
  TranslatedModule,
  TranslatedPage,
} from "../types/index.js";
import { checkIsDragDropData } from "../utils/utils.js";
import PageModule from "./PageModule.js";

interface Props {
  activeId: string | undefined;
  index: number;
  languageId: Language;
  module: Module<ModuleSettingsTypes>;
  onModuleSelect: (
    translatedModule: TranslatedModule,
    languageId: Language,
  ) => void;
  onRef: (node: HTMLDivElement | null) => void;
  page: TranslatedPage;
  queries: ContainerQueries;
}

const SortableModule: FunctionComponent<Props> = ({
  activeId,
  index,
  languageId,
  module,
  onModuleSelect,
  onRef,
  page,
  queries,
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
    isSorting,
    activeIndex,
    over,
    index: sortableIndex,
    active,
  } = useSortable({
    id: module.id,
    data: {
      type: DragDropType.Module,
      id: module.id,
    } satisfies DragDropTypes[DragDropType.Module],
    disabled: true,
  });

  const style = {
    transform: isSorting ? undefined : CSS.Translate.toString(transform),
    transition,
  };

  return (
    <PageModule
      key={module.id}
      activeId={activeId}
      languageId={languageId}
      module={module}
      page={page}
      queries={queries}
      index={index}
      onModuleSelect={onModuleSelect}
      ref={(node) => {
        setNodeRef(node);
        onRef(node);
      }}
      divProps={{
        style,
        ...attributes,
        ...listeners,
      }}
      isDragging={isDragging}
      insertPosition={
        over?.id === module.id &&
        active &&
        checkIsDragDropData(active.data, DragDropType.Module)
          ? getInsertPosition({ sortableIndex, activeIndex })
          : undefined
      }
    />
  );
};

const getInsertPosition = ({
  sortableIndex,
  activeIndex,
}: {
  sortableIndex: number;
  activeIndex: number;
}): ModuleSortInsertPosition | undefined => {
  if (sortableIndex > activeIndex) return ModuleSortInsertPosition.After;
  if (sortableIndex < activeIndex) return ModuleSortInsertPosition.Before;
  return undefined;
};

export default SortableModule;
